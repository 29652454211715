import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';

import { SecondeTabs, TabPane } from 'base-components';
import cn from 'classnames';
import { useIsomorphicLayoutEffect } from 'hooks';
import { useRouter } from 'next/router';
import { pubsub } from 'utils';

import {
  HOME_PATHS,
  QUESTION_SLUG,
  REFRESH,
  RELATION_TYPE
} from '@constants/index';
import {
  IWeb3Auth,
  TRexContext,
  useAnswerListPage,
  withLogin
} from '@hooks/index';
import { I18n } from '@utils/index';

import { homeTabsEum } from '../feedTabs/const';
import { CommunityFeed } from './index';

import styles from './socialFeed.module.less';

const FollowFeed: React.FC<{
  activeKey: string;
  key: string;
  web3Auth: IWeb3Auth;
  scrollRef?: HTMLElement | null | undefined;
}> = (props) => {
  const { className, web3Auth, scrollRef } = props;
  const {
    state: { isLogin }
  } = useContext(TRexContext);
  const [key, setActiveKey] = useState('community');
  const { rows, status, nextToken, fetch } = useAnswerListPage({
    slug: QUESTION_SLUG.RELATION_COMMUNITY,
    reverse: true,
    limit: 20,
    with_activity: true,
    with_community: true,
    own_relation: [RELATION_TYPE.JOIN]
  });
  const router = useRouter();
  const cacheRef = useRef<any>({});
  const tokenRef = useRef<any>({});
  const { activeKey, followKey } = router.query;
  const onChange = (key: string) => {
    if (!isLogin) {
      web3Auth?.current?.handleLogin?.();
      return;
    }
    setActiveKey(key);
    console.log('line47', cacheRef.current[followKey as string]);
    const query = router.query;
    router.replace({
      pathname: HOME_PATHS.INDEX,
      query: {
        ...query,
        followKey: key
      }
    });
  };
  useIsomorphicLayoutEffect(() => {
    if (activeKey === homeTabsEum.follow && isLogin) {
      setActiveKey(followKey as string);
      getData(followKey, true);
    }
  }, [activeKey, followKey, isLogin]);
  const refresh = ({ pathname, query }) => {
    if (pathname !== HOME_PATHS.INDEX || query.activeKey !== homeTabsEum.follow) return;
    cacheRef.current[query.followKey] = [];
    tokenRef.current[query.followKey] = [];
    getData(query.followKey, true);
  };
  useIsomorphicLayoutEffect(() => {
    pubsub.subscribe(REFRESH, refresh);
    return () => pubsub.unSubscribe(REFRESH, refresh);
  }, []);

  const getData = useCallback(
    async (value: any, init?: boolean, nextToken?: string) => {
      if (!isLogin) {
        return;
      }
      if (init && cacheRef.current[value]?.length) return;
      if (value === 'community') {
        const res = await fetch({ nextToken: nextToken, init: init });
        cacheRef.current.community = res?.rows;
        tokenRef.current.community = res?.token;
      } else {
        const res = await fetch({
          nextToken: nextToken,
          slug: QUESTION_SLUG.RELATION_USER,
          ownCommunity: [RELATION_TYPE.FOLLOW],
          withCommunity: false,
          withActivity: false,
          withCollection: true,
          aCate: '29,30',
          init: init
        });
        cacheRef.current.user = res?.rows;
        tokenRef.current.user = res?.token;
      }
    },
    [fetch, isLogin]
  );
  const onScroll = () => {
    if (!nextToken.current || !tokenRef.current[key]) {
      return;
    }
    getData(key, false, tokenRef.current[key]);
  };
  const data = useMemo(() => {
    if (rows.length < 10 && nextToken.current && isLogin) {
      onScroll();
    }
    const keys = Object.keys(cacheRef.current || {});
    const data =
      keys.reduce((prev, curr) => {
        prev[`${curr}Data`] = cacheRef.current[curr];
        return prev;
      }, {}) || {};
    return data;
  }, [key, fetch]);
  return (
    <div className={cn(styles.wrapper, className)}>
      <SecondeTabs
        onChange={onChange}
        className={styles.secTabWrapper}
        activeKey={key}
      >
        <TabPane tab={<h2>{I18n.t('communityTab')}</h2>} key="community">
          <CommunityFeed
            activeKey={'community'}
            data={data?.[`${key}Data`]}
            status={status}
            onScroll={onScroll}
            nextToken={tokenRef.current?.community}
            scrollRef={scrollRef}
          />
        </TabPane>
        <TabPane tab={<h2>{I18n.t('userTab')}</h2>} key="user">
          <CommunityFeed
            activeKey={'user'}
            data={data?.[`${key}Data`]}
            status={status}
            onScroll={onScroll}
            nextToken={tokenRef.current?.user}
            scrollRef={scrollRef}
          />
        </TabPane>
      </SecondeTabs>
    </div>
  );
};

export default withLogin(FollowFeed);
