import React from 'react';

import type { ImageGroupPreviewProps } from 'base-components/image-group-preview/type'
import { ImageGroupPreview } from 'base-components/index';

const TRexImageGroupPreview: React.FC<ImageGroupPreviewProps> = (props) => {
  return (
    <ImageGroupPreview
      placeholderSrc={require('./kiki_preload.webp')}
      {...props}
   />
  )
}

export default TRexImageGroupPreview;
