import React, { useCallback, useContext } from 'react';
import { keepAliveDropCache } from 'react-next-keep-alive';

import { Button, Menu } from 'base-components/index';
import cn from 'classnames';
import { AddIcon } from 'icons';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { HOME_PATHS } from '@constants/index';
import { TRexContext, withLogin } from '@hooks/index';

import { menuKeysEum, menuPath, REQUIRE_LOGIN_ROUTER } from './const';
// import { AnswerIcon, HomeIcon, ProfileIcon, TopIcon } from './media/index';
import type { menuProps } from './type';

import styles from './index.module.less'

// const icons = {
//   Home: <HomeIcon />,
//   Answer: <AnswerIcon />,
//   Top: <TopIcon />,
//   Profile: <ProfileIcon />
// }
const items = Object.keys(menuKeysEum).map(
  key => {
    const val = menuKeysEum[key] || '';
    return {
      key,
      icon: <></>,
      label: val,
      title: ''
    };
  }
);
const TRexMenu: React.FC<menuProps> = (props) => {
  const router = useRouter();
  const { state: { isLogin, userInfo } } = useContext(TRexContext);
  const { onCreate, className, web3Auth, onLogoClick } = props;
  const onSelect = (e) => {
    if (REQUIRE_LOGIN_ROUTER.some((i) => i === e.key) && !isLogin) {
      web3Auth?.current?.handleLogin?.();
      return;
    }
    router.push(menuPath[e.key])
    keepAliveDropCache('', false);
  };
  const onPost = useCallback(() => {
    if (!isLogin) {
      web3Auth?.current?.handleLogin?.();
      return;
    }
    onCreate();
  }, [
    isLogin, web3Auth,
    userInfo?.avatar, userInfo?.nickName, userInfo?.uniqueName
  ])
  return (
    <div className={styles.menuWrapper}>
      <Link href={{
        pathname: HOME_PATHS.INDEX,
        query: router.pathname === HOME_PATHS.INDEX ? router.query : undefined
      }} className={styles.logo} onClick={() => onLogoClick?.(HOME_PATHS.INDEX)}>
        <Image alt="logo" className={styles.img} src={require('./media/Trex-logo.webp')} />
      </Link>
      <Menu
        {...props}
        className={cn(styles.trexMenu, className, 'openSansSemiBold')}
        items={items}
        theme='dark'
        onSelect={onSelect}
      />
      <Button type="primary" className={styles.post} onClick={onPost}>Post</Button>
      <div className={styles.add} onClick={onPost}>
        <AddIcon />
      </div>
    </div>
  )
}

export default withLogin(TRexMenu);
