import React, { memo } from 'react'

import { ScrollLoading } from 'base-components'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { Empty } from '@components/index'
import { I18n } from '@utils/index';

import { useScroll } from './hooks/useFetch'

import styles from './List.module.less'

dayjs.extend(utc)

const T:React.FC = () => {
  const { status, onScroll, dataSource, hasMore } = useScroll()
  console.log(hasMore, 'hasMorehasMore')
  return (
    <ScrollLoading
      invisibleHeight={100}
      status={status}
      fetchData={onScroll}
      noMoreShow={true}
      renderNoMore={<div className={styles.tip}>{I18n.t('noMoreContents')}</div>}
    >
      <div className={styles.table}>
          {
          dataSource.length > 0 ? dataSource.map((i, index) => (
            <div className={styles.item} key={index}>
                <div className={styles.left}>
                    <div className={styles.title}>{I18n.t(i.descI18n)}</div>
                    <div className={styles.time}>{dayjs.utc(i.created).local().format('MM/DD/YYYY HH:mm:ss')}</div>
                </div>
                <div className={styles.points}>
                  {I18n.t('Q&ANum', { points: i.amount })}
                </div>
            </div>
          ))
            : <Empty
              emptyTitle={I18n.t('emptyWater')}
              emptyDesc={I18n.t('emptyWaterDes')}
            />
          }
          {/* {
            dataSource.length > 0 && (
              <div className={styles.tip}>There are no more tasks</div>
            )
          } */}
      </div>
    </ScrollLoading>
  )
}

export const List = memo(T)
