import React from 'react';

import cn from 'classnames';

import { Empty } from '@components/index';
import { IWeb3Auth } from '@hooks/index';
import { I18n } from '@utils/index';

import styles from './index.module.less';

export const AboutTab: React.FC<{
  web3Auth?: IWeb3Auth;
  about: string;
}> = (props) => {
  const { about } = props;
  if (!about) {
    return (
      <Empty
        className={styles.empty}
        emptyTitle={I18n.t('emptyNormal')}
        emptyDesc=" "
      />
    );
  }
  return <div className={cn(styles.container)}>{about}</div>;
};
