import React, { useCallback, useContext, useMemo, useRef } from 'react';

import cn from 'classnames';
import cookie from 'js-cookie';
import { useRouter } from 'next/router';
import { openFollowersModal, PersonalInfoHeader } from 'widgets';

import { getFollowerList, getFollowingList } from '@api/index';
import { PROFILE } from '@constants/index';
import { TRexContext } from '@hooks/index';
import { useFollowUser } from '@hooks/user'
import { type IProfileRouterQuery } from '@widget/index';

import { ProfileHeaderProps, ProfileHeaderRefCurrent } from './type'
import { useUpdate } from './useUpdate';

import styles from './index.module.less';

const ProfileHeaderWithLogin = React.forwardRef<ProfileHeaderRefCurrent, ProfileHeaderProps>((props: ProfileHeaderProps, ref) => {
  const {
    className, web3Auth, following, isMySelf, isFollow,
    handleFollow, handleEditProfile, curSocialUserInfo, initing
  } = props;
  const router = useRouter();
  const headerRef = useRef(null);
  const { state } = useContext(TRexContext);
  const {
    walletAddress,
    isLogin
  } = state;
  const { id } = router.query as IProfileRouterQuery;
  const userId = cookie.get('userId');

  const {
    activity_like_count: activityLikeCount = 0,
    avatar: initAvatar,
    background,
    intro,
    unique_name: uniqueName,
    nick_name: nickName,
    follower_count: followerCount = 0,
    following_count: followingCount = 0
  } = curSocialUserInfo;
  const { onClickAvatar, onClickBg } = useUpdate(web3Auth)
  const defaultImg = require('@components/Avatar/media/defaultAvatar.webp');

  const getProfileLink = (profileId) => {
    return `${PROFILE}?id=${profileId}`
  }

  const onClickFollowers = useCallback((activeKey) => {
    if (!isLogin) {
      web3Auth?.current?.handleLogin?.();
      return;
    }
    openFollowersModal({
      id: isMySelf ? userId : id,
      isMySelf,
      requestFollowerApi: getFollowerList,
      requestFollowingApi: getFollowingList,
      activeKey,
      followerCount,
      followingCount,
      useFollowUser,
      getProfileLink,
      wrapper: headerRef?.current
    })
  }, [isMySelf, userId, id, isLogin, web3Auth, headerRef, followerCount, followingCount])

  const avatat = initAvatar || defaultImg;
  const backgroundImg = useMemo(() => {
    if (background) {
      return background
    }
    if (initAvatar) {
      return initAvatar
    }
    return defaultImg.default.src
  }, [background, initAvatar, defaultImg])

  return (
    <div className={cn(styles.container, className)} ref={headerRef}>
      <PersonalInfoHeader
        isMySelf={isMySelf}
        nickName={nickName}
        uniqueName={uniqueName}
        address={walletAddress}
        desc={intro}
        avatar={avatat}
        bgImg={backgroundImg}
        likes={activityLikeCount}
        handleEditProfile={handleEditProfile}
        onClickFollowers={onClickFollowers}
        handleFollow={handleFollow}
        followerCount={followerCount}
        followingCount={followingCount}
        following={following}
        isFollow={isFollow}
        onClickAvatar={onClickAvatar}
        onClickBg={onClickBg}
        initing={initing}
      />
    </div>
  );
})

ProfileHeaderWithLogin.displayName = 'ProfileHeaderWithLogin';
export const ProfileHeader = ProfileHeaderWithLogin;
