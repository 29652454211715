import { useRef } from 'react';

import { useIsomorphicLayoutEffect } from 'hooks';
import throttle from 'lodash/throttle';

import { UseStickyParams } from './type';

import styles from './index.module.less';
export const useSticky = ({ scrollEl, itemRef, isPc }: UseStickyParams) => {
  const containerRef = useRef<any>(null);
  const inputRef = useRef<any>(null);

  const onScroll = (e) => {
    if (!inputRef.current) return;
    const scrollTop = e.target.scrollTop;
    const classList = Array.from(containerRef.current.classList);
    if ((scrollTop > itemRef?.current?.offsetTop + inputRef.current.offsetTop + inputRef.current.clientHeight) && (
      !classList.includes(styles.flexBox)
    )) {
      containerRef.current.classList.add(styles.flexBox);
      // 评论item完全出现在视图
    } else if ((scrollTop < itemRef?.current?.offsetTop + containerRef?.current?.offsetTop + 32) && (
      classList.includes(styles.flexBox)
    )) {
      containerRef.current.classList.remove(styles.flexBox);
    }
  }

  useIsomorphicLayoutEffect(() => {
    if (!isPc) return;
    // @ts-ignore
    scrollEl?.addEventListener('scroll', throttle(onScroll, 200));
    return () => {
      scrollEl?.removeEventListener('scroll', throttle(onScroll, 200));
    }
  }, [isPc])
  return { inputRef, containerRef }
}
