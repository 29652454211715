import React from 'react';

import cn from 'classnames';

import { StickyItem } from '@components/index';

import { FollowedItemHeader } from './followedItemHeader';
import { FollowedItemProps } from './type';

import styles from './index.module.less';
export const FollowedItem: React.FC<FollowedItemProps> = (props) => {
  const {
    title,
    communityName,
    avatar,
    className,
    nickName,
    userName,
    linkTo,
    created,
    headerInfo,
    ...rest
  } = props;
  return (
    <StickyItem
      {...rest}
      header={
        <FollowedItemHeader
          title={title}
          nickName={nickName}
          userName={userName}
          communityName={communityName}
          avatar={avatar}
          linkTo={linkTo}
          created={created}
          headerInfo={headerInfo}
        />
      }
      className={cn(styles.followedItem, className, 'openSansSemiBold')}
    />
  );
};
