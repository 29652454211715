import React from 'react';

import cn from 'classnames';
import { customizeRelativeTime } from 'utils';

import { Avatar } from '@components/index';

import { FollowedItemHeaderProps } from './type';

import styles from './index.module.less';
export const FollowedItemHeader: React.FC<FollowedItemHeaderProps> = (props) => {
  const {
    title,
    communityName,
    avatar,
    linkTo,
    created,
    nickName,
    userName,
    headerInfo
  } = props;
  const isSquare = !!communityName;

  return (
    <div className={styles.community}>
      <Avatar
        linkTo={linkTo}
        nickName={
          <div className={styles.userInfo}>
            <div className={styles.user}>
              <div className={styles.name}>{communityName || nickName}</div>
              {userName && <div className={styles.userName}>{userName}</div>}
            </div>
            <div className={styles.userDesc}>
              {headerInfo}
              {!!created && (
                <div className={styles.time}>{`· ${customizeRelativeTime(created)}`}</div>
              )}
            </div>
          </div>
        }
        isSquare={isSquare}
        avatarSize={40}
        avatar={avatar}
      />
      <div className={cn(styles.question, 'openSansSemiBold')}>{title}</div>
    </div>
  );
};
