import React, { memo } from 'react'

import { type ITabsProps, Tabs } from 'base-components'

import { ACHIEVE_LIST } from '@constants/index'
import { I18n } from '@utils/index';

import { List } from '../list'

import styles from './PointsDetail.module.less'

const T:React.FC = () => {
  const items: ITabsProps['items'] = [
    {
      key: '1',
      label: <span className='openSansSemiBold'>{I18n.t('detailClaimed')}</span>,
      children: <List />
    },
    {
      key: '2',
      label: <span className='openSansSemiBold'>{I18n.t('detailConsumed')}</span>
    }
  ];
  return (
    <div className={styles.detailContent} id={ACHIEVE_LIST}>
      <Tabs activeKey='1' items={items}/>
    </div>
  )
}

export const PointsDetail = memo(T)
