import React, { forwardRef, MutableRefObject, useImperativeHandle, useState } from 'react';

import { Button, injectDom } from 'base-components';
import { Comment } from 'widgets';

import { CommentItem } from '@components/comment-item';
import { PROFILE, REACTION_TYPE } from '@constants/index';
import { useCommentData } from '@hooks/index';
import { getUserInfo } from '@utils/index';

import { ReplyListProps, ReplyListRefType } from './type';

import styles from './index.module.less';

const LIMIT = 20;

const ReplyListComponent: React.ForwardRefRenderFunction<ReplyListRefType, ReplyListProps> = (props, ref) => {
  const {
    parentId,
    defaultReplyList = [],
    replayCount = 0,
    web3Auth
  } = props;
  const [isFirst, setIsFirst] = useState(true);
  const userInfo = getUserInfo()

  const {
    nextToken,
    commentData,
    fetchData,
    refreshData,
    saveComment
  } = useCommentData({
    web3Auth,
    isReply: true,
    answerId: parentId,
    defaultCommentList: defaultReplyList,
    defaultHasMore: replayCount > defaultReplyList.length,
    limit: LIMIT,
    defaultLoad: false
  });

  useImperativeHandle(ref, () => ({
    handleSubmit
  }));

  const loadMore = () => {
    if (isFirst) {
      refreshData();
      setIsFirst(false);
      return
    }
    fetchData()
  }

  const handleSubmit = async ({
    id,
    value,
    removePreview,
    resolve
  }: {
    id: string;
    value: string;
    resolve: <P = unknown>(value?: P) => void;
    removePreview: () => void;
  }) => {
    try {
      const target = commentData.find(item => item.view_id === id);
      const res = await saveComment(id, value, REACTION_TYPE.REPLY, target);
      if (res?.success) {
        removePreview();
        resolve();
        return Promise.resolve(true);
      }
      return Promise.resolve(false);
    } catch (error) {
      return Promise.resolve(false);
    }
  };

  const onClickCommentReplay = async (ref: MutableRefObject<HTMLElement>, id: string) => {
    injectDom({
      wrapper: ref.current,
      children: (params) => <Comment className={styles.replayComment} onSubmit={(value) =>
        handleSubmit({ id, value, ...params })}
        avatar={userInfo?.avatar}
        getUserInfo={getUserInfo}
      />
    });
  };

  return (
    <div className={styles.replayList}>
      <div>
        {
          commentData?.map(item => {
            const {
              view_id: viewId,
              data: { text = '' } = {},
              user: {
                nick_name: username = '',
                avatar = '', id
              } = {},
              like_count: likeCount,
              created,
              target: {
                view_id: targetId,
                user: {
                  nick_name: replyUsername,
                  id: replyId
                } = {}
              } = {},
              owns
            } = item;
            return (
              <CommentItem
                key={viewId}
                text={text}
                username={username}
                created={created}
                userAvatar={avatar}
                id={viewId}
                linkTo={PROFILE}
                customerId={`?id=${id}`}
                replyId={replyId}
                likeCount={likeCount}
                isLike={owns?.LIKE}
                replayUsername={parentId !== targetId ? replyUsername : undefined}
                contentClassName={styles.commentContent}
                avatarSize={28}
                borderLeftLine
                onClickReplay={onClickCommentReplay}
              />
            )
          })
        }

      </div>
      {
        nextToken &&
        <Button
          className={styles.viewMoreReplay}
          type="primary"
          ghost
          onClick={loadMore}
        >
          View more
        </Button>
      }
    </div>
  )
}

export const ReplyList = forwardRef<ReplyListRefType, ReplyListProps>(ReplyListComponent);
