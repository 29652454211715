import React, { useState } from 'react';

import { GoBack, If } from 'base-components';
import cn from 'classnames';
import { useClient, useIsomorphicLayoutEffect } from 'hooks';
import { useRouter } from 'next/router';
import { pubsub } from 'utils';
import { RenderBtn } from 'widgets/pageHeader/personalHomeHeader/render-btn'

import { LoginInfo } from '@components/index';
import { FOLLOW_USER_SUCCESS } from '@constants/index';
import { type IProfileRouterQuery } from '@widget/index';

import { PageNavProps } from './type';

import styles from './PageNav.module.less';

const PageNav: React.FC<PageNavProps> = (props) => {
  const { isMySelf, isFollow, following, handleEditProfile, handleFollow, tipContent, initing } = props;
  const router = useRouter();

  const { id } = router.query as IProfileRouterQuery;
  const [isFollowShow, setIsFollow] = useState(props.isFollow)
  const isPc = useClient(576);

  const onFollowChange = (params) => {
    if (params.targetUserId === id) {
      setIsFollow(params.isFollowNew)
    }
  }

  useIsomorphicLayoutEffect(() => {
    pubsub.subscribe(FOLLOW_USER_SUCCESS, onFollowChange);
    return () => {
      pubsub.unSubscribe(FOLLOW_USER_SUCCESS, onFollowChange);
    };
  }, [id]);

  useIsomorphicLayoutEffect(() => {
    setIsFollow(isFollow);
  }, [isFollow])

  const customizeSize = isPc ? 'default' : 'xsmall'
  return (
    <div className={styles.container}>
      <div className={cn(styles.left, 'openSansSemiBold')}>
        <If
          condition={!!isMySelf}
          true={<div className={cn(styles.tipContent, 'openSansSemiBold')}>{tipContent}</div>}
          false={<GoBack text={'Back'} />}
        />
        <If
          condition={(isMySelf === undefined) || initing}
          true={null}
          false={<RenderBtn
            isMySelf={isMySelf}
            isFollow={isFollowShow}
            loading={following}
            handleEditProfile={handleEditProfile}
            handleFollow={handleFollow}
            customizeSize={customizeSize}
            className={styles.button}
          />}
        />
      </div>
      <div className={styles.right}>
        <LoginInfo />
      </div>
    </div>
  );
};

export default PageNav
