import React, { useCallback, useMemo } from 'react';

import { ScrollLoading, SPIN_STATUS } from 'base-components';
import cn from 'classnames';
import { useIsomorphicLayoutEffect } from 'hooks';

import { Empty } from '@components/index';
import { QUESTION_SLUG } from '@constants/index';
import { useAnswerListPage, useQuestionDetail } from '@hooks/index';
import { I18n } from '@utils/index';
import { RecommendItem } from '@widget/index';

import { AnswerTabProps } from './type';

import styles from './index.module.less';

export const AnswerTab: React.FC<AnswerTabProps> = (props) => {
  const { id, className, scrollEl } = props;
  const { rows, status, nextToken, fetch } = useAnswerListPage({
    slug: QUESTION_SLUG.COMMUNITY_ANSWER,
    limit: 20,
    with_activity: true,
    'params[a_community]': id
  });
  const { jumpDetail } = useQuestionDetail();
  const getData = useCallback(() => {
    if (id) {
      fetch({ init: true });
    }
  }, [id, fetch]);

  useIsomorphicLayoutEffect(() => {
    getData();
  }, [id]);

  const onScroll = () => {
    if (!nextToken.current) {
      return;
    }
    fetch({});
  };

  const data = useMemo(() => {
    const list = rows?.filter((item) => {
      return item.activity;
    });
    if (list.length < 10) {
      onScroll();
    }
    console.log('line32', list);
    return list;
  }, [rows]);
  return (
    <div className={cn(styles.container, className)}>
      {status === SPIN_STATUS.SUCCESS && data.length === 0 && (
        <Empty emptyTitle={I18n.t('emptyNormal')} emptyDesc=" " />
      )}
      <ScrollLoading
        invisibleHeight={100}
        status={status}
        fetchData={onScroll}
        noMoreShow={!nextToken.current && data?.length > 0}
      >
        {data.map((item) => {
          const {
            view_id: id = '',
            title,
            user: { id: userId },
            activity: {
              comment_count: commentCount = 0,
              like_count: likeCount = 0,
              text: richContent = '',
              view_id: answerId = '',
              created: answerTime,
              user: {
                nick_name: nickName = '',
                unique_name: userName = '',
                avatar = '',
                id: answerUserId = ''
              } = {},
              owns: { LIKE: isLike = false } = {}
            } = {}
          } = item;

          return (
            <RecommendItem
              title={title}
              scrollEl={scrollEl}
              richContent={richContent}
              nickName={nickName}
              userName={userName}
              answerId={answerId}
              answerUserId={answerUserId}
              likeCount={likeCount}
              avatar={avatar}
              commentCount={commentCount}
              answerTime={answerTime}
              questionId={id}
              isLike={isLike}
              key={id}
              handleItemClick={(params) => jumpDetail({ ...params, questionUserId: userId, questionId: id })}
            />
          );
        })}
      </ScrollLoading>
    </div>
  );
};
