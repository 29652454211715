import { ACHIEVE, ANSWER, HOME, PROFILE, TOP } from '@constants/index';
import I18n from '@utils/i18n';

export const menuKeysEum = {
  Home: I18n.t('Home'),
  Top: I18n.t('Top'),
  Answer: I18n.t('Answer'),
  Achieve: 'Achieve',
  Profile: I18n.t('Profile')
}

export const menuPath = {
  Home: `${HOME}?activeKey=recommend`,
  Top: TOP,
  Answer: ANSWER,
  Achieve: ACHIEVE,
  Profile: PROFILE
}

export const REQUIRE_LOGIN_ROUTER = ['Profile', 'Achieve']
