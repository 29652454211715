import React from 'react';

import { message, ScrollLoading, SPIN_STATUS } from 'base-components'
import { useRouter } from 'next/router';

import { dynamicTypeEum } from '@api/index';
import { Empty } from '@components/index';
import { COMMUNITY_PATHS, QUESTION_STATUS } from '@constants/index';
import { useDynamic, useQuestionDetail } from '@hooks/index';
import I18n from '@utils/i18n';
import { ActivityItem } from '@widget/index';

import { ActivityFeedProps, IDynamicItemProps } from './type';

export const ActivityFeed: React.FC<ActivityFeedProps> = (props) => {
  const { scrollEl } = props;
  const { status, dynamicList, onScroll, nextToken } = useDynamic();
  const { jumpDetail } = useQuestionDetail();
  const router = useRouter();

  const handleDynamicDetail = (item: IDynamicItemProps) => {
    const {
      verb, questionId = '',
      questionUserId = '', answerId = '',
      answerUserId = '', status, communityId = ''
    } = item;
    if (status === QUESTION_STATUS.STOP) {
      message.info(I18n.t('collection.not.found'))
      return;
    }
    if (verb === dynamicTypeEum.JOIN_COMMUNITY) {
      router.push({
        pathname: COMMUNITY_PATHS.COMMUNITY_DETAIL.replace('[id]', communityId)
      });
      return;
    }
    jumpDetail({
      questionId,
      questionUserId,
      answerId,
      answerUserId
    });
  };

  return (
    <div>
      <ScrollLoading
        invisibleHeight={100}
        status={status}
        fetchData={onScroll}
        noMoreShow={!nextToken.current && dynamicList?.length !== 0}
      >
        {status === SPIN_STATUS.SUCCESS && dynamicList.length === 0 && <Empty emptyTitle={I18n.t('emptyNormal')} emptyDesc=" " />}
        {dynamicList.map((item) => {
          return (
            <ActivityItem
              {...item}
              key={item?.id}
              scrollEl={scrollEl}
              handleItemClick={handleDynamicDetail}
            />
          )
        })}
      </ScrollLoading>
    </div>
  );
};
