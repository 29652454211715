import React, { useCallback, useContext, useRef, useState } from 'react';

import { More } from 'atom-components';
import { injectDom, isIncomplete, RichEditor } from 'base-components';
import cn from 'classnames';
import { useIsomorphicLayoutEffect } from 'hooks';
import { ArrowExpand } from 'icons';

import { Operate } from '@components/index';
import { TRexContext, withLogin } from '@hooks/index';
import I18n from '@utils/i18n';
import { isInViewPort } from '@utils/index';
import { CommentList, openCommentListModal } from '@widget/index';

import { StickyItemProps } from './type';

import styles from './index.module.less';
const StickyItemComponent: React.FC<StickyItemProps> = (props) => {
  const {
    header,
    className,
    defaultIsExpend = false,
    scrollEl,
    web3Auth,
    maxLength = 270,
    ...otherProps
  } = props;
  const {
    richContent,
    answerId,
    likeCount,
    commentCount,
    viewCount,
    isLike,
    status,
    handleItemClick,
    replyUserId,
    curAnswerId
  } = otherProps;
  // 是否有更多内容，默认有展示更多
  const [moreState, setMoreState] = useState(!defaultIsExpend);
  const [isSticky, setIsSticky] = useState(false);
  const [curCommentCount, setCurCommentCount] = useState(commentCount);
  const [isShowHideBtn, setIsShowHideBtn] = useState(false);
  const itemRef = useRef<any>(null);
  const isStickyRef = useRef(isSticky);
  const {
    state: { isLogin }
  } = useContext(TRexContext);
  const scrollPostionRef = useRef<any>(null);
  // 内容展示 & 隐藏
  const editorRef = useRef<any>(null);
  // 内容是否超出ref节点
  const editorWrapperRef = useRef<any>(null);
  // 评论放在哪个地方的ref
  const commentRef = useRef<any>(null);

  const isClick = typeof handleItemClick === 'function';

  useIsomorphicLayoutEffect(() => {
    let timer;
    const isMore = isIncomplete(richContent, maxLength);
    if (defaultIsExpend) {
      setIsSticky(isMore);
      isStickyRef.current = isMore;
      setMoreState(!isMore);
    }
    if (defaultIsExpend && (!!replyUserId && curAnswerId === answerId)) {
      // 点击回复进入问题详情，默认打开评论区域
      timer = setTimeout(() => {
        handleCommentContent();
      }, 200)
    }
    return () => clearTimeout(timer);
  }, []);
  const isScroll = scrollPostionRef.current !== null;

  const onMoreClick = (state) => {
    const inViewPort = editorWrapperRef.current
      ? isInViewPort(editorWrapperRef.current)
      : false;
    // state: 是否收起
    setMoreState(state);
    if (state) {
      setIsSticky(false);
      isStickyRef.current = false;
      (!inViewPort && isScroll) && scrollEl?.scrollTo({ top: scrollPostionRef.current });
      return;
    }
    scrollPostionRef.current = scrollEl?.scrollTop;
    isStickyRef.current = !inViewPort;
    setIsSticky(!inViewPort);
  };
  useIsomorphicLayoutEffect(() => {
    // 未登录情况，收起评论流
    if (!isLogin) {
      handleHideComment();
    }
  }, [isLogin]);

  const handleHideComment = () => {
    const inViewPort = isInViewPort(editorWrapperRef.current);
    // 收起评论
    setIsShowHideBtn(false);
    commentRef?.current.firstChild && commentRef?.current?.removeChild(commentRef?.current.firstChild);
    (!inViewPort && isScroll) && scrollEl?.scrollTo({ top: scrollPostionRef.current });
  };
  const handleHideContent = () => {
    editorRef.current?.onMoreClick(true);
  };
  const onCommentSuccess = () => {
    setCurCommentCount((preCommentCount) => preCommentCount + 1);
  };
  const showCommentModal = async () => {
    // 回复的时候，close函数更新外层评论列表，重新插入;
    // isShowHideBtn值为false;
    const { queryLastBulletin } = await import('@widget/comment/list/utils');
    const dbData = await queryLastBulletin();
    let defaultValue = '';
    if (dbData?.id === answerId) {
      defaultValue = dbData?.value;
    }
    openCommentListModal({
      answerId: answerId as string,
      inModal: true,
      handleHideComment,
      onCommentSuccess,
      defaultValue,
      web3Auth
    });
  };
  const handleStickItemClick = () => {
    isClick && handleItemClick({ ...otherProps });
  };

  const handleCommentContent = useCallback(() => {
    scrollPostionRef.current = scrollEl?.scrollTop;
    if (!isShowHideBtn && isStickyRef.current) {
      showCommentModal();
      return;
    }
    // 使用feed流形式评论
    // 再次点击评论，收起评论
    if (isShowHideBtn) {
      handleHideComment();
      return;
    }
    setIsShowHideBtn(true);
    injectDom({
      wrapper: commentRef.current,
      children: () => (
        <CommentList
          web3Auth={web3Auth}
          answerId={answerId as string}
          onCommentSuccess={onCommentSuccess}
          scrollEl={scrollEl}
          itemRef={itemRef}
          handleHideComment={handleHideComment}
        />
      ),
      unique: false
    });
  }, [answerId, isShowHideBtn, isSticky]);
  console.log('scrollEl', scrollEl);
  return (
    <div
      className={cn(styles.container, className, {
        [styles.expand]: !moreState,
        [styles.jumpContainer]: isClick
      })}
      ref={itemRef}
    >
      <div className={cn(styles.content)}>
        <div className={cn(styles.mainContent)}>
          <div onClick={handleStickItemClick}>
            {typeof header === 'function' ? header() : header}
            <div ref={editorWrapperRef}>
              <RichEditor
                ref={editorRef}
                readOnly
                isImages
                className={styles.editor}
                defaultIsExpend={defaultIsExpend}
                maxLength={maxLength}
                defaultValue={richContent}
                moreClass={styles.action}
                more={<More isExpand={false} className={styles.arrowExpand} />}
                hide={null}
                hideChange={onMoreClick}
              />
            </div>
          </div>
          <div
            className={cn(styles.stickyArea, {
              [styles.sticky]: isSticky
            })}
          >
            <Operate
              comment={handleCommentContent}
              likeCount={likeCount}
              commentCount={curCommentCount}
              id={answerId}
              status={status}
              viewCount={viewCount}
              isLike={isLike}
            />
            {!moreState && (
              <div
                className={cn(
                  styles.action,
                  styles.actionBtn,
                  'openSansSemiBold'
                )}
                onClick={handleHideContent}
              >
                {I18n.t('hideContent')}
                <ArrowExpand className={styles.hideArrowSvg} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.commentWrapper}>
          <div ref={commentRef}></div>
        </div>
      </div>
    </div>
  );
};

export const StickyItem = withLogin(StickyItemComponent);
