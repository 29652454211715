import React, { useCallback, useState } from 'react';

import { message } from 'base-components'
import cn from 'classnames';
import { isEmpty } from 'lodash';
import throttle from 'lodash/throttle';

import { addLike, deleteLike } from '@api/index';
import { QUESTION_STATUS } from '@constants/index';
import { withLogin } from '@hooks/index';
import { getUserInfo, handleNoLogin, I18n, noLoginCodeEum } from '@utils/index';

import { CommentIcon, LikeIcon } from './media/index';
import { IOperateProps } from './type';

import styles from './index.module.less';
const OperateWithLogin: React.FC<IOperateProps> = (props) => {
  const {
    className, web3Auth, id, targetId, viewCount, status,
    comment, isLike, likeCount, commentCount
  } = props;
  const userInfo = getUserInfo();
  const [like, setLike] = useState(isLike);
  const [curLikeCount, setCurLikeCount] = useState(likeCount);
  const isHasComment = typeof comment === 'function';
  const isShowLikeCount = typeof likeCount !== 'undefined';
  const isShowCommentCount = typeof commentCount !== 'undefined';
  const isShowViewCount = typeof viewCount !== 'undefined';
  const isLogin = !isEmpty(userInfo);
  const throttleHandleLike = async () => {
    if (!isLogin) {
      web3Auth?.current?.handleLogin?.();
      return;
    }
    if (status === QUESTION_STATUS.STOP) {
      message.info(I18n.t('operateStopDesc'));
      return;
    }
    try {
      let res = {} as any;
      if (like) {
        res = await deleteLike({
          targetViewId: targetId || id
        });
      } else {
        res = await addLike({
          targetViewId: id,
          userId: userInfo.id,
          target: targetId
        })
      }
      if (!res?.success) {
        // 9998: 未登录
        if (res.code === noLoginCodeEum.ksocial) {
          handleNoLogin();
          // 问题被暂停1008，提示内容与点击操作区域不一致
        } else if (res.code === 1008) {
          message.error(I18n.t('operateStopDesc'));
        } else {
          message.error(I18n.t(res?.msg));
        }
        return;
      }
      setCurLikeCount((preLikeCount) => {
        return like ? (preLikeCount || 0) - 1 : (preLikeCount || 0) + 1
      })
      setLike((preState) => !preState);
    } catch (err) { }
  }
  const handleLike = useCallback(throttle(throttleHandleLike, 500, {
    leading: false,
    trailing: true
  }), [id, isLogin, like, targetId, web3Auth]);
  const handleComment = useCallback(async () => {
    if (status === QUESTION_STATUS.STOP) {
      message.info(I18n.t('operateStopDesc'));
      return;
    }
    isHasComment && comment();
  }, [isHasComment, comment, status]);
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.interact}>
        <div className={styles.like} onClick={handleLike}>
          <LikeIcon className={cn({
            [styles.liked]: like,
            [styles.unlike]: !like
          })} />
          {isShowLikeCount && <span className={styles.likeCount}>{curLikeCount}</span>}
        </div>
        {isHasComment && <div className={styles.comment} onClick={handleComment}>
          <CommentIcon />
          {isShowCommentCount && <span className={styles.commentCount}>{commentCount}</span>}
        </div>}
        {
          isShowViewCount && (
            <div className={styles.view}>
              {viewCount} {I18n.t('answerViews')}
            </div>
          )
        }
      </div>
    </div>
  )
}

export const Operate = withLogin(OperateWithLogin);
