import React, { Dispatch, useEffect, useState } from 'react';

import { Button, Input, message, openModal, TextArea } from 'base-components';
import cn from 'classnames'
import { useClient } from 'hooks';
import { Cemera } from 'icons';
import cookie from 'js-cookie'
import Image from 'next/image';
import type { IAliyunOSS } from 'utils/oss/type';
import { openEditImage } from 'widgets/edit-avatar';

import type { ISocialUserInfo, IUserInfo } from '@api/index';
import { modifyFerchSocialUserInfo, updateUserInfo } from '@api/index';
import { type IAction, type IState, IActionEum } from '@hooks/index';
import I18n from '@utils/i18n';

import styles from './index.module.less';

type ContentPropsTypes = {
  close?: () => void;
  alioss: any;
  state: IState;
  dispatch: Dispatch<IAction>;
};

const Content: React.FC<ContentPropsTypes> = ({ close, alioss, state, dispatch }) => {
  const [allUserinfo, setAllUserinfo] = useState<IUserInfo & ISocialUserInfo & { hasBlur: any }>(
    {} as IUserInfo & ISocialUserInfo & { hasBlur: any }
  );
  const [loading, setloading] = useState(false);

  const { userInfo, socialUserInfo } = state || {};
  const isPc = useClient(576);
  useEffect(() => {
    const avatar = userInfo?.avatar || require('@components/Avatar/media/defaultAvatar.webp').default.src
    setAllUserinfo({
      ...{
        ...socialUserInfo,
        background: socialUserInfo?.background || avatar
      },
      ...{
        ...userInfo,
        avatar: avatar
      },
      hasBlur: !socialUserInfo?.background
    });
  }, []);

  const nickNameChange = (e) => {
    userInfoChange('nickName', e.target.value)
  };
  const introNameChange = (e) => {
    userInfoChange('intro', e.target.value)
    // userInfoChange('hasBlur', false)
  };

  const userInfoChange = (key, value) =>
    setAllUserinfo({
      ...allUserinfo,
      [key]: value
    });

  const onSubmit = async () => {
    setloading(true);
    const res = await Promise.all([
      updateUserInfo({
        nickName: allUserinfo.nickName,
        avatar: allUserinfo.avatar
      }),
      modifyFerchSocialUserInfo({
        id: cookie.get('userId'),
        data: {
          intro: allUserinfo?.intro || '',
          background: allUserinfo?.background || ''
        }
      })
    ])

    if (res.some((i) => !i)) {
      setloading(false);
      return
    }
    const updateUserData = {
      intro: allUserinfo?.intro || '',
      background: allUserinfo?.background || '',
      nick_name: allUserinfo.nickName,
      avatar: allUserinfo.avatar
    }
    dispatch({
      type: IActionEum.UPDATE_USERINFO,
      payload: {
        ...userInfo,
        avatar: allUserinfo.avatar,
        nickName: allUserinfo.nickName
      }
    });
    dispatch({
      type: IActionEum.UPDATE_SOCIAL_USERINFO,
      payload: {
        ...socialUserInfo,
        ...updateUserData
      }
    })
    message.success(I18n.t('updateSuccess'))
    close?.();
  }

  console.log(allUserinfo, 'allUserinfo')
  const modifyBackground = async () => {
    const newBackground = await openEditImage({
      imgUrl: allUserinfo.background,
      type: 'background',
      alioss
    });
    setAllUserinfo({
      ...allUserinfo,
      background: newBackground as string,
      hasBlur: false
    });
  };

  const modifyAvatar = async () => {
    const newAvatar = await openEditImage({
      imgUrl: allUserinfo.avatar,
      type: 'avatar',
      alioss
    });
    setAllUserinfo({
      ...allUserinfo,
      avatar: newAvatar as string
    });
    if (allUserinfo.hasBlur) {
      setAllUserinfo({
        ...allUserinfo,
        avatar: newAvatar as string,
        hasBlur: false,
        background: newAvatar as string
      });
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.scrollContent}>
        <Button
          type="primary"
          className={styles.uploadButton}
          colorType="black"
          onClick={modifyBackground}
        >
          {I18n.t('uploadCover')}
        </Button>
        <div className={cn(
          { [styles.imgBackground]: !allUserinfo?.hasBlur },
          { [styles.imgBackground2]: allUserinfo?.hasBlur }
        )}>
          {
            allUserinfo?.background && (
              <div
                className={cn(styles.img)}
                style={{
                  backgroundImage: `url(${allUserinfo?.background})`
                }}
              />
            )
          }
        </div>
        <div className={styles.bottom}>
          {
            !isPc && (
              <div className={styles.cameraBackground} onClick={modifyAvatar}>
                <Cemera className={styles.camera} />
              </div>
            )
          }
          <div className={styles.avatar}>
            {
              isPc && (
                <div className={styles.mask} onClick={modifyAvatar}>
                  <Cemera className={styles.camera} />
                </div>
              )
            }
            <Image
              alt="TRex"
              src={allUserinfo?.avatar}
              width={120}
              height={120}
            />
          </div>
          <Input
            placeholder={I18n.t('namePlaceholder')}
            value={allUserinfo?.nickName || ''}
            onChange={nickNameChange}
            maxLength={15}
            className={styles.nicknameInput}
          />
          <TextArea
            placeholder={I18n.t('desPlaceholder')}
            className={styles.textArea}
            value={allUserinfo?.intro || ''}
            onChange={introNameChange}
            maxLength={300}
          />
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <Button
          type="primary"
          btnClassName={styles.button}
          className={styles.buttonContain}
          onClick={onSubmit}
          loading={loading}
          disabled={!allUserinfo?.nickName}
        >
          {I18n.t('save')}
        </Button>
      </div>
    </div>
  );
};

interface ParamsType {
  alioss: IAliyunOSS, state?: any, dispatch?: any
}

export const OpenModifyPersonalMsgModal = async ({ alioss, state, dispatch }: ParamsType) => {
  const res = await openModal({
    title: <span className='openSansSemiBold'>{I18n.t('editProfile')}</span>,
    mask: true,
    maskClosable: false,
    closable: true,
    className: styles.modal,
    cardClass: styles.cardClass,
    closeClass: styles.closeClass,
    headerClass: styles.headerClass,
    children: (params) => <Content {...params} alioss={alioss} state={state} dispatch={dispatch} />
  });
  return res;
};
