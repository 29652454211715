import React from 'react';

import { CopyBtn } from 'base-components/index';
import cn from 'classnames';
import { useClient } from 'hooks/index';
import { CopyIcon, LogoutIcon } from 'icons';

import { Avatar } from '@components/index';
import { PROFILE_PATHS } from '@constants/index';

import type { PopConProps } from './type';

import styles from './navBar.module.less';

const PopContent: React.FC<PopConProps> = (props) => {
  const {
    avatar,
    nickName = '',
    userName,
    address,
    onClick,
    resolve,
    close,
    className,
    isDrawer
  } = props;
  const isPc = useClient(576);
  const onDisconnect = () => {
    if (isDrawer) {
      resolve?.(true);
      close?.();
    }
    onClick?.();
  };
  return (
    <div className={cn(styles.loginContent, className)}>
      <div className={styles.userInfo}>
        <Avatar
          avatarSize={isPc ? 64 : 40}
          avatar={avatar}
          linkTo={PROFILE_PATHS.INDEX}
        // userName={'dfgfd'}
        />
        <div className={styles.nickName}>
          <span>{address}</span>
          <CopyBtn
            text={nickName}
            customerIcon={
              <span className={styles.iconWrapper}>
                <CopyIcon className={styles.copyIcon} />
              </span>
            }
          />
        </div>
        <p className={styles.userName}>{userName}</p>
      </div>
      <div className={styles.connect} onClick={onDisconnect}>
        <LogoutIcon className={styles.logoutIcon} /> <span>Disconnect</span>
      </div>
    </div>
  );
};

export default PopContent;
