import React, { useCallback, useRef } from 'react';

import { ScrollLoading, SPIN_STATUS } from 'base-components';
import cn from 'classnames';
import { useIsomorphicLayoutEffect } from 'hooks';
import { useRouter } from 'next/router';
import { AnswerItem } from 'widgets';

import { Empty } from '@components/index';
import { QUESTION_PATHS, QUESTION_SLUG } from '@constants/index';
import { useAddAnswer, useAnswerListPage, withLogin } from '@hooks/index';
import { getAnswerAndPlateMsg } from '@utils/answer/helper';
import { I18n } from '@utils/index';

import { QuestionTabProps } from './type';

import styles from './index.module.less';

const QuestionTab: React.FC<QuestionTabProps> = (props) => {
  const { id, className, web3Auth } = props;
  const { rows, status, nextToken, fetch } = useAnswerListPage({
    slug: QUESTION_SLUG.COMMUNITY,
    limit: 20,
    with_user: true,
    with_activity: false,
    with_community: true,
    'params[a_community]': id
  });
  const { onClickAnswerFun } = useAddAnswer({ web3Auth });
  const FeedRef = useRef(null);
  const router = useRouter();

  const getData = useCallback(() => {
    if (id) {
      fetch({ init: true });
    }
  }, [id, fetch]);

  useIsomorphicLayoutEffect(() => {
    getData();
  }, [id]);

  const onScroll = () => {
    if (!nextToken.current) {
      return;
    }
    fetch({});
  };
  const handleQuestionDetail = (item) => {
    const { questionId = '', customerId } = item;
    router.push({
      pathname: QUESTION_PATHS.QUESTION_DETAIL.replace('[id]', questionId),
      query: { questionUserId: customerId }
    });
  };
  return (
    <div className={cn(styles.container, className)} ref={FeedRef}>
      {status === SPIN_STATUS.SUCCESS && rows.length === 0 && (
        <Empty emptyTitle={I18n.t('emptyNormal')} emptyDesc=" " />
      )}
      <ScrollLoading
        invisibleHeight={100}
        status={status}
        fetchData={onScroll}
        noMoreShow={!nextToken.current && rows.length > 0}
      >
        {rows.map((item) => {
          const {
            view_id: id = '',
            title,
            created,
            user_id: userId,
            child_activity_count: activityCount,
            user,
            community
          } = item;
          const res = getAnswerAndPlateMsg(user, community);
          return (
            <AnswerItem
              handleItemClick={(params) => handleQuestionDetail({ ...params, customerId: userId })}
              key={id}
              questionId={id}
              title={title}
              created={created}
              activityCount={activityCount}
              onClickAnswer={() => {
                onClickAnswerFun?.(item);
              }}
              {...res}
              linkTo=""
            />
          );
        })}
      </ScrollLoading>
    </div>
  );
};

export default withLogin(QuestionTab);
