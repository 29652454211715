import { useContext, useEffect, useState } from 'react';

import { pubsub } from 'utils';

import { getAchievement } from '@api/index'
import { ACHIEVE_REFRESH } from '@constants/index';
import { TRexContext } from '@hooks/index';

export const useAchieve = () => {
  const [points, setPoints] = useState(0);
  const { state: { isLogin } } = useContext(TRexContext);

  useEffect(() => {
    pubsub.subscribe(ACHIEVE_REFRESH, fetchoints)
    return () => pubsub.unSubscribe(ACHIEVE_REFRESH, fetchoints)
  }, [])

  useEffect(() => {
    if (!isLogin) {
      setPoints(0)
      return
    }
    fetchoints()
  }, [isLogin])

  const fetchoints = async () => {
    const { obj, success } = await getAchievement()
    if (success) {
      setPoints(obj.point)
    }
  }

  return points
}
