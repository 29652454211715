import { useState } from 'react';

import { message } from 'base-components'
import { pubsub } from 'utils';
import I18n from 'widgets/add-answer/utils/i18n';

import { followUser, unFollowUser } from '@api/index';

export const useFollowUser = () => {
  const [loading, setLoading] = useState(false);

  const successPubsub = (targetId, isFollow) => {
    pubsub.publish('FOLLOW_USER_SUCCESS', {
      targetUserId: targetId,
      isFollowNew: isFollow
    })
  }

  const followFun = async (targetId) => {
    setLoading(true)
    try {
      const res = await followUser(targetId)
      if (res.success) {
        successPubsub(targetId, true);
      } else {
        message.error(I18n.t('operationFailure'));
      }
    } catch (e) {
      console.log('follow-Error:::', e)
    }
    setLoading(false)
  }

  const unFollowFun = async (targetId) => {
    setLoading(true)

    try {
      const res = await unFollowUser(targetId)
      if (res.success) {
        successPubsub(targetId, false);
      } else {
        message.error(I18n.t('operationFailure'))
      }
    } catch (e) {
      console.log('unfollow-Error:::', e)
    }
    setLoading(false)
  }

  const onFollow = (isFollow, targetId) => {
    if (isFollow) {
      unFollowFun(targetId)
    } else {
      followFun(targetId);
    }
  };

  return {
    onFollow,
    loading
  }
};
