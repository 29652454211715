import React from 'react';

import { BackTop, IBackTopProps } from 'base-components';
import cn from 'classnames';

import BackTopIcon from './media/backTop.svg';

import styles from './backtop.module.less';

export const TrexBackTop: React.FC<IBackTopProps> = (props) => {
  const { className, ...rest } = props;
  return (
    <BackTop
      className={cn(className, styles.backTop)}
      {...rest}
      icon={rest.icon || <BackTopIcon />}
    />
  );
};
