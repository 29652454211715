import React, { useMemo } from 'react';

import { ScrollLoading, SPIN_STATUS } from 'base-components';
import cn from 'classnames';
import Link from 'next/link';

import { Empty } from '@components/index';
import { useAddAnswer, useQuestionDetail, withLogin } from '@hooks/index';
import { I18n, linkToMap } from '@utils/index';
import { FollowedAnswerItem } from '@widget/index';

import { FollowedWrapper } from './FollowedItemWrapper';
import { type followFeedProps } from './type';

import styles from './socialFeed.module.less';

const CommunityFeed: React.FC<followFeedProps> = (props) => {
  const {
    className,
    activeKey,
    data,
    status,
    onScroll,
    nextToken,
    web3Auth,
    scrollRef
  } = props;
  const { onClickAnswerFun } = useAddAnswer({ web3Auth });
  const { jumpDetail } = useQuestionDetail();

  const currentStatus = useMemo(() => {
    return !data ? SPIN_STATUS.LOADING : status;
  }, [status]);
  return (
    <div className={cn(className)}>
      {(currentStatus === SPIN_STATUS.SUCCESS && data?.length === 0) ? <Empty /> : <ScrollLoading
        invisibleHeight={100}
        status={status}
        fetchData={onScroll}
        noMoreShow={!nextToken?.current && data?.length > 0}
        className={styles.followWrapper}
      >
        {data?.map((item, index) => {
          const {
            community: {
              id: communityId = '',
              title: communityName = '',
              icon = ''
            } = {},
            user: {
              avatar = '',
              id: userId = '',
              nick_name: name,
              unique_name: uniqueName = ''
            } = {}
          } = item;
          const linkTo = linkToMap[activeKey](communityId || userId);
          return (
            <>
              {((item?.activity || item?.category === 'ANSWER'))
                ? <FollowedWrapper
                  key={index + item?.view_id}
                  item={item} linkTo={linkTo} activeKey={activeKey} scrollRef={scrollRef} />
                : <FollowedAnswerItem
                  key={index + item?.view_id}
                  created={item?.created}
                  avatar={icon || avatar}
                  communityName={communityName}
                  nickName={name}
                  userName={activeKey === 'user' ? uniqueName : ''}
                  questionId={item?.view_id}
                  title={item?.title}
                  linkTo={linkTo}
                  headerInfo={
                    activeKey === 'community'
                      ? <Link className={styles.postAnswer} href={linkToMap.user(userId)}>Asked by<span>{name}</span></Link>
                      : <div className={styles.postAnswer}>{I18n.t('postedQuestion')}</div>
                  }
                  handleItemClick={(params) => jumpDetail({ ...params, questionUserId: userId })}
                  onClickAnswerFun={() => {
                    onClickAnswerFun?.(item);
                  }}
                  activityCount={activeKey === 'community' ? null : item?.child_activity_count}
                  answerDesc={I18n.t('lookingAnswer')}
                />}
            </>
          );
        })}
      </ScrollLoading>}
    </div>
  );
};

export default withLogin(CommunityFeed);
