import React from 'react';

import cn from 'classnames';
import Link from 'next/link';

import styles from './navList.module.less';
export const NavList: React.FC<{ activeKey?: string, list: Array<any> }> = (props) => {
  const { className, activeKey, list } = props;
  return (
    <ul className={cn(className, styles.tabList)}>
      {list.map((item) => (
        <li
          key={item.key}
          className={cn({
            [styles.active]: activeKey === item.key
          })}
        >
          <Link href={item.path} className={cn('openSansSemiBold')}>
            {item.value}
          </Link>
        </li>
      ))}
    </ul>
  );
};
