import React, { MutableRefObject, useRef } from 'react';

import { injectDom } from 'base-components';
import { Comment } from 'widgets';

import { CommentItem } from '@components/index';
import { PROFILE, REACTION_TYPE } from '@constants/index';
import { withLogin } from '@hooks/index';
import { getUserInfo } from '@utils/user';

import { ReplyList, ReplyListRefType } from '../replay-list';
import { ItemProps } from './type';

import styles from './index.module.less';

const ItemComponent: React.FC<ItemProps> = (props) => {
  const { commentItem, web3Auth } = props;
  const replyListRef = useRef<ReplyListRefType>(null);
  const userInfo = getUserInfo();

  const {
    view_id: viewId,
    data: { text = '' } = {},
    user: {
      nick_name: username = '',
      avatar = '', id
    } = {},
    like_count: likeCount,
    reply_count: replayCount,
    created,
    children,
    owns
  } = commentItem;

  const onClickCommentReplay = async (ref: MutableRefObject<HTMLElement>, id: string) => {
    injectDom({
      wrapper: ref.current,
      children: (params) => <Comment className={styles.comment} onSubmit={(value) => {
        if (replyListRef.current) {
          return replyListRef.current?.handleSubmit({ id, value, ...params, kind: REACTION_TYPE.REPLY })
        }
        return Promise.resolve(false);
      }}
        avatar={userInfo?.avatar}
        getUserInfo={getUserInfo}
      />
    });
  };

  return (
    <>
      <CommentItem
        key={viewId}
        id={viewId}
        text={text}
        username={username}
        userAvatar={avatar}
        linkTo={PROFILE}
        customerId={`?id=${id}`}
        likeCount={likeCount}
        replyCount={replayCount}
        created={created}
        isLike={owns?.LIKE}
        onClickReplay={onClickCommentReplay}
      />
      <ReplyList
        ref={replyListRef}
        parentId={viewId}
        replayCount={replayCount}
        defaultReplyList={children?.REPLY || []}
        web3Auth={web3Auth}
      />
    </>
  )
};

export const Item: React.FC<ItemProps> = React.memo(withLogin(ItemComponent));
