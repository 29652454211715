import { useContext, useRef } from 'react';

import { message } from 'antd';
import type { ImageProps } from 'next/image'
import { type PersonalInfoHeaderProps, openEditImage } from 'widgets';

import { modifyFerchSocialUserInfo, updateUserInfo } from '@api/index';
import { IActionEum, IWeb3Auth, TRexContext } from '@hooks/index';
import { I18n } from '@utils/index';
import { alioss } from '@utils/oss';

import { ActionMap, FnMap, UpdateType } from './type';

/** 修改用户头像背景图 hook */
export const useUpdate = (web3Auth: IWeb3Auth) => {
  const { state: { isLogin, userInfo: { id } }, dispatch } = useContext(TRexContext);
  const fnMap = useRef<FnMap>();
  const actionMap = useRef<ActionMap>();
  fnMap.current = {
    avatar: (avatar: string) => updateUserInfo({ avatar }),
    background: async (background: string) => {
      const res = await modifyFerchSocialUserInfo({ id, data: { background } })
      return { ...res, success: res.msg === 'success' }
    }
  }
  actionMap.current = {
    avatar: (avatar: string) => {
      dispatch({ type: IActionEum.UPDATE_USERINFO, payload: { avatar } });
      dispatch({ type: IActionEum.UPDATE_SOCIAL_USERINFO, payload: { avatar } });
    },
    background: (background: string) => dispatch({ type: IActionEum.UPDATE_SOCIAL_USERINFO, payload: { background } })
  }
  const update = async (imgUrl: ImageProps['src'], type: UpdateType) => {
    if (!isLogin) return web3Auth?.current?.handleLogin?.();
    const img = await openEditImage({ imgUrl, type, alioss });
    if (!fnMap.current) return;
    const { success } = await fnMap.current[type](img as string);
    if (!success) return;
    if (!actionMap.current) return;
    actionMap.current[type](img as string)
    message.success(I18n.t('updateSuccess'))
  }
  const onClickAvatar: PersonalInfoHeaderProps['onClickAvatar'] = (img) => update(img, 'avatar');
  const onClickBg: PersonalInfoHeaderProps['onClickBg'] = (img) => update(img || '', 'background');
  return {
    onClickAvatar,
    onClickBg
  }
}
