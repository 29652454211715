import React, { useCallback, useContext } from 'react';

import { message, ScrollLoading, SPIN_STATUS } from 'base-components'
import { useIsomorphicLayoutEffect } from 'hooks';
import { useRouter } from 'next/router';

import { Empty } from '@components/index';
import { QUESTION_STATUS } from '@constants/index';
import { slugEum, TRexContext, useCommendList, useQuestionDetail } from '@hooks/index';
import I18n from '@utils/i18n';
import { type IProfileRouterQuery, type RecommendItemProps, RecommendItem } from '@widget/index';

type AnswerFeedProps = {
  scrollEl?: HTMLElement | null | undefined;
}

export const AnswerFeed: React.FC<AnswerFeedProps> = (props) => {
  const { className, scrollEl } = props;
  const { state: { isLogin, userInfo } } = useContext(TRexContext);
  const { status, commendList, fetchCommendData, nextToken } = useCommendList();
  const { jumpDetail } = useQuestionDetail();
  const router = useRouter();
  const userId = userInfo?.id || '';
  const { id } = router.query as IProfileRouterQuery;
  const isMySelf = !id || id === userId;
  const commonParams = {
    id: isMySelf ? userId : id,
    slug: slugEum.aggregated_user_answer,
    withCollection: true,
    'params[a_user]': isMySelf ? userId : id,
    reactionKindsFilter: [
      'LIKE'
    ]
  }
  const getData = useCallback(() => {
    fetchCommendData({ ...commonParams, init: true });
  }, [fetchCommendData]);

  useIsomorphicLayoutEffect(() => {
    if (!isLogin && !id) return;
    getData();
  }, [id, isLogin, userInfo?.avatar, userInfo?.nickName, userInfo?.uniqueName]);
  const onScroll = () => {
    if (!nextToken.current) {
      return;
    }
    fetchCommendData(commonParams);
  };

  const handleQuestionDetail = (item: RecommendItemProps & { questionUserId: string }) => {
    const { status, questionId = '' } = item;
    if (status === QUESTION_STATUS.STOP) {
      message.info(I18n.t('collection.not.found'))
      return;
    }
    jumpDetail({ ...item, questionId });
  };

  return (
    <div className={className}>
      <ScrollLoading
        invisibleHeight={100}
        status={status}
        fetchData={onScroll}
        noMoreShow={!nextToken.current && commendList?.length !== 0}
      >
        {status === SPIN_STATUS.SUCCESS && commendList.length === 0 && <Empty emptyTitle={I18n.t('emptyNormal')} emptyDesc=" " />}
        {commendList.map((item) => {
          const {
            view_id: answerId = '',
            like_count: likeCount = 0,
            comment_count: activityCount = 0,
            created,
            collection: {
              view_id: id,
              title,
              status,
              user_id: userId
            } = {},
            owns: { LIKE: isLike = false } = {},
            text,
            user: {
              nick_name: nickName = '',
              unique_name: uniqueName = '',
              avatar = '',
              id: answerUserId = ''
            } = {}
          } = item;

          return (
            <RecommendItem
              title={title}
              scrollEl={scrollEl}
              richContent={text}
              nickName={nickName}
              userName={uniqueName}
              answerId={answerId}
              answerUserId={answerUserId}
              likeCount={likeCount}
              avatar={avatar}
              status={status}
              commentCount={activityCount}
              questionId={id}
              isLike={isLike}
              key={answerId}
              handleItemClick={(params) => handleQuestionDetail({ ...params, questionUserId: userId as string })}
              answerTime={created}
            />
          );
        })}
      </ScrollLoading>
    </div>
  );
};
