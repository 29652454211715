import React, { useState } from 'react';

import { TabPane, Tabs } from 'base-components/index';
import cn from 'classnames';
import { useIsomorphicLayoutEffect } from 'hooks';
import { useRouter } from 'next/router';

import { COMMUNITY_PATHS } from '@constants/index';
import I18n from '@utils/i18n';

import { AboutTab, AnswerTab, QuestionTab } from '../'
import { CommunityTabProps } from './type'

import styles from './CommunityFeedTabs.module.less';

const CommunityFeedTabs: React.FC<CommunityTabProps> = (props) => {
  const { id, className, about, scrollEl } = props;

  const [activeKey, setActiveKey] = useState('cAbout');
  const router = useRouter();

  const onChange = (activeKey) => {
    setActiveKey(activeKey);
    router.replace({
      pathname: COMMUNITY_PATHS.COMMUNITY_DETAIL.replace('[id]', id),
      query: {
        activeKey
      }
    });
  }

  useIsomorphicLayoutEffect(() => {
    setActiveKey(router.query.activeKey as string);
  }, [router]);
  return (
    <div className={cn(styles.container, className)}>
      <Tabs
        type="line"
        onChange={onChange}
        activeKey={activeKey}
        className={cn(styles.tabWrapper, `tab-${activeKey}`)}
      >
        <TabPane tab={<h2>{I18n.t('about')}</h2>} key="cAbout">
          <AboutTab
            about={about}
          />
        </TabPane>
        <TabPane tab={<h2>{I18n.t('questions')}</h2>} key="cQuestion">
          <QuestionTab
            id={id}
          />
        </TabPane>
        <TabPane tab={<h2>{I18n.t('answer')}</h2>} key="cAnswer">
          <AnswerTab
            id={id}
            scrollEl={scrollEl}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};
export default CommunityFeedTabs;
