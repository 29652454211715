import React, { ReactNode } from 'react';

import cn from 'classnames';

import { useAsideHeight } from './utils';

import styles from './mainContent.module.less';
export const MainContent: React.FC<{ contentRight?: ReactNode; isHidden?: boolean; className?: string; }> = (props) => {
  const { className, children, contentRight, isHidden = false } = props;
  const { ref } = useAsideHeight();
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.left}>
        {children}
      </div>
      <div className={cn(styles.right, {
        [styles.isHiddenRight]: isHidden
      })}>
        <div ref={ref}>
          {contentRight}
        </div>
      </div>
    </div>
  );
};
