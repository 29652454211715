import React from 'react';

import cn from 'classnames';
import Image from 'next/image';

import I18n from '@utils/i18n';

import styles from './index.module.less';
const Empty = (props) => {
  const { className, emptyTitle, emptyDesc } = props;
  return (
    <div className={cn(styles.container, 'openSansSemiBold', className)}>
      <Image
        alt="TRex"
        className={styles.image}
        src={require('./media/empty.webp')}
      />
      <div className={styles.title}>{emptyTitle || I18n.t('emptyTitle')}</div>
      <div className={styles.desc}>
        {emptyDesc || I18n.t('emptyDesc')}
      </div>
    </div>
  );
};

export { Empty };
