import React from 'react';

import type { FeedImageProps } from 'base-components/feed-image/type'
import { FeedImage } from 'base-components/index';

const TRexFeedImage: React.FC<FeedImageProps> = (props) => {
  return (
    <FeedImage
      placeholderSrc={require('./kiki_preload.webp')}
      {...props}
   />
  )
}

export default TRexFeedImage;
