import React from 'react';

import { RichEditor } from 'base-components/rich-editor';
import cn from 'classnames';

import { Avatar } from '@components/index';
import { PROFILE } from '@constants/index';
import { useQuestionDetail } from '@hooks/index';
import I18n from '@utils/i18n';
import { CommunityItem } from '@widget/index';

import { ReplyItemProps } from './type';

import styles from './index.module.less';

export const ReplyItem: React.FC<ReplyItemProps> = (props) => {
  const {
    className, replyUserId,
    richContent, nickName, avatar,
    userName, maxLength = 258, ...otherProps
  } = props;
  const { questionId, questionUserId, answerId, answerUserId, onLinkClick } = otherProps;
  const { jumpDetail } = useQuestionDetail();
  // 点击评论内容，跳转携带replyUserId，默认打开评论
  const handleReplyClick = (e) => {
    e.stopPropagation();
    jumpDetail({
      questionId,
      questionUserId,
      answerId,
      answerUserId,
      replyUserId
    });
  }
  return (
    <div className={cn(styles.replyItem, className)}>
      <div className={styles.userInfoWrapper}>
        <div className={styles.userDesc}>{I18n.t('commentedDesc')}</div>
        <Avatar
          nickName={
            <div className={styles.userInfo}>
              <span className={cn(styles.nickName, 'openSansSemiBold')}>{nickName}</span>
              <span className={cn('openSansSemiBold', styles.userName)}>{userName}</span>
            </div>
          }
          avatarSize={20}
          avatar={avatar}
          linkTo={`${PROFILE}?id=${replyUserId}`}
          onLinkClick={onLinkClick}
        />
      </div>
      <div onClick={handleReplyClick}>
        <RichEditor
          readOnly
          maxLength={maxLength}
          defaultValue={richContent}
          className={styles.editor}
        />
      </div>
      <CommunityItem
        {...otherProps}
        // 回答被删除
        isDelete={!otherProps?.desc}
        imgWidth={192}
        imgHeight={120}
        titleLineCount={2}
        className={cn(styles.answerItem, {
          [styles.imgItem]: !!otherProps?.imgUrl
        })}
        imgClassName={styles.answerImg}
      />
    </div>
  );
};
