import { useContext } from 'react';

import { updateUserInfo } from '@api/index';
import { IActionEum, TRexContext } from '@hooks/index';
import I18n from '@utils/i18n';
const avatars = [
  'https://upload.trexprotocol.com/public/head1.webp',
  'https://upload.trexprotocol.com/public/head2.webp',
  'https://upload.trexprotocol.com/public/head3.webp',
  'https://upload.trexprotocol.com/public/head4.webp',
  'https://upload.trexprotocol.com/public/head5.webp',
  'https://upload.trexprotocol.com/public/head6.webp',
  'https://upload.trexprotocol.com/public/header7.webp',
  'https://upload.trexprotocol.com/public/header8.webp',
  'https://upload.trexprotocol.com/public/header9.webp',
  'https://upload.trexprotocol.com/public/header10.webp'
];
/** 初次登陆设置用户信息的校验 */
const useOnVerify = () => {
  const { state, dispatch } = useContext(TRexContext);
  const { userInfo, walletAddress = '' } = state;
  const onVerify = async (value: string) => {
    const avatar = avatars[Math.floor(Math.random() * 6)];
    const params = {
      uniqueName: `@${value}`,
      customerId: userInfo.id,
      nickName: walletAddress.slice(0, 6),
      avatar: avatar
    }
    try {
      const res = await updateUserInfo(params);
      if (res.success) {
        dispatch({
          type: IActionEum.UPDATE_USERINFO,
          payload: {
            ...userInfo,
            ...params
          }
        });
      }
      return {
        success: res?.success,
        msg: I18n.t(res.msgKey)
      };
    } catch {
      console.log('system.error');
      return {
        success: false,
        msg: I18n.t('system.error')
      };
    }
  };
  return {
    onVerify
  }
};

export { useOnVerify };
