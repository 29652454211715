import React from 'react';

import { Button, GoBack } from 'base-components';
import cn from 'classnames';
import { useClient } from 'hooks';

import { LoginInfo } from '@components/index';
import { I18n } from '@utils/index';

import styles from './PageNav.module.less';

const PageNav = (props) => {
  const { isJoin, onFollow, following } = props;
  const isPc = useClient(576);

  const btnColorType = isJoin ? 'black' : 'green';
  const customizeSize = isPc ? 'default' : 'xsmall'

  return (
    <div className={styles.container}>
      <div className={cn(styles.left, 'openSansSemiBold')}>
        <GoBack text={'Back'} />
        <Button
          type='primary'
          size='middle'
          customizeSize={customizeSize}
          colorType={btnColorType}
          className={styles.followBtn}
          onClick={onFollow}
          disabled={following}
        >
          {isJoin ? I18n.t('unFollow') : I18n.t('follow')}
        </Button>
      </div>
      <div className={styles.right}>
        <LoginInfo />
      </div>
    </div>
  );
};

export default PageNav
