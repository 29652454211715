/* eslint-disabled */
import React, { useRef } from 'react';

import { openModal, ScrollLoading } from 'base-components';
import { Comment } from 'widgets';

import { withLogin } from '@hooks/index';
import { getUserInfo } from '@utils/user';

import { CommentList, CommentListRefType } from '../list';
import { CommentListModalProps } from './type';

import styles from './index.module.less';

const LIMIT = 20;
const CommentListModalComponent: React.FC<CommentListModalProps> = (props) => {
  const { answerId, defaultValue = '' } = props;
  const userInfo = getUserInfo()
  const commentListRef = useRef<CommentListRefType>(null);

  const onScroll = async () => {
    commentListRef.current?.fetchData();
    return Promise.resolve();
  };

  /** 提交评论 */
  const onSubmitComment = async (value: string) => {
    try {
      const res = await commentListRef.current?.handleSubmit({ id: answerId, value });
      return Promise.resolve(!!res);
    } catch (error) {
      return Promise.resolve(false);
    }
  }
  const onBlur = async (value) => {
    const { addData, updateBulletin, queryLastBulletin } = await import(
      '../list/utils/commentDb'
    );
    const dbData = await queryLastBulletin();
    console.log('line56', dbData);
    const newData = {
      id: answerId,
      value: value
    };
    if (dbData?._rev) {
      updateBulletin({
        ...dbData,
        ...newData
      });
      return;
    }
    addData(newData);
  };
  return (
    <div className={styles.commentListContainer}>
      <div className={styles.scrollContainer}>
        <ScrollLoading invisibleHeight={100} fetchData={onScroll}>
          <CommentList ref={commentListRef} {...props} />
        </ScrollLoading>
      </div>
      <div className={styles.commentAnd}>
        <Comment
          avatar={userInfo?.avatar}
          getUserInfo={getUserInfo}
          onSubmit={onSubmitComment}
          onBlur={onBlur}
          defaultValue={defaultValue}
        />
      </div>
    </div>

  );
};

const CommentListModal = withLogin(CommentListModalComponent);

const openCommentListModal = async (props: CommentListModalProps) => {
  return openModal({
    mask: true,
    closable: true,
    closePosition: 'right',
    title: 'All Comment',
    cardClass: styles.modalBody,
    headerClass: styles.modalHeader,
    className: styles.maskClass,
    children: (params) => <CommentListModal {...params} {...props} defaultLimit={LIMIT} limit={LIMIT} />
  });
};

export { openCommentListModal };
