import React from 'react';

import { Layout } from 'antd';
import { GoBack } from 'base-components/index';
import cn from 'classnames';

import { useAddQuestion } from '@hooks/index';

import { NavBar } from '../index';
import TRexMenu from './components/menu';
import { LayoutProps } from './type';

import styles from './index.module.less';

const { Header, Content, Sider } = Layout;

export const TRexLayout: React.FC<LayoutProps> = (props) => {
  const { children, isShow = true, className, defaultKey = '', isBack, contentRight, contentLayoutClassName = '' } = props;

  const { onClickPostFun } = useAddQuestion();

  const onCreate = () => {
    onClickPostFun?.()
  }
  return (
    <Layout className={cn(styles.layout, className)} hasSider>
      {/* 通过breakpoint 控制在移动端的sider尺寸 lg:992 */}
      <Sider
        breakpoint="lg"
        width="250"
        collapsedWidth="108"
        className={cn(styles.sider, {
          [styles.isShowSider]: !isShow
        })}
      >
        <TRexMenu
          mode="inline"
          selectedKeys={[defaultKey]}
          style={{ height: '100%', borderRight: 0 }}
          onCreate={onCreate}
        />
      </Sider>
      <Layout className={styles.secLayout}>
        <Header className={styles.header}>
          <NavBar
            tipContent={
              !isBack ? (
                <span className={cn(styles.navText, 'openSansSemiBold')}>{defaultKey}</span>
              ) : (
                <GoBack text={'Back'} />
              )
            }
          />
        </Header>
        <Content className={cn(styles.content, contentLayoutClassName)}>
          <div className={styles.left}>
            {children}
          </div>
          {contentRight && <div className={styles.right}>
            <div>
              {contentRight}
            </div>
          </div>}
        </Content>
      </Layout>
    </Layout>
  );
};

// export default TRexLayout;
