import React from 'react';

import { customizeRelativeTime } from 'utils/index';

import { dynamicTypeEum } from '@api/index';
import I18n from '@utils/i18n';

import { DistributeItem } from './distributeItem';
import { ActivityItemProps } from './type';

import styles from './index.module.less';

export const ActivityItem: React.FC<ActivityItemProps> = (props) => {
  const { created, verb } = props;
  const dynamicDescEum = {
    [dynamicTypeEum.ANSWER]: I18n.t('answeredQuestion'),
    [dynamicTypeEum.COMMENT_ANSWER]: I18n.t('commentedAnswer'),
    [dynamicTypeEum.JOIN_COMMUNITY]: I18n.t('followedCommunity'),
    [dynamicTypeEum.LIKE_ANSWER]: I18n.t('LikeAnswer'),
    [dynamicTypeEum.PUBLISH_COLLECTION]: I18n.t('publishedQuestion')
  }
  return (
    <div className={styles.activityItem}>
      <div className={styles.headerTips}>{dynamicDescEum[verb]} · {customizeRelativeTime(created)}</div>
      <DistributeItem {...props} />
    </div>
  );
};
