import React from 'react';

import { AnswerItem } from 'widgets';

import { FollowedItemHeader } from '../followedItem/followedItemHeader';
import { AnswerItemProps } from './type';

import styles from './index.module.less';

export const FollowedAnswerItem: React.FC<AnswerItemProps> = (props) => {
  const {
    created,
    communityName,
    avatar,
    linkTo,
    time,
    nickName,
    userName,
    headerInfo,
    onClickAnswerFun,
    ...othersProps
  } = props;

  return (
    <>
      <AnswerItem
        className={styles.followedAnswerItem}
        customUserInfo={(
          <FollowedItemHeader
            communityName={communityName}
            nickName={nickName}
            userName={userName}
            avatar={avatar}
            headerInfo={headerInfo}
            linkTo={linkTo}
            created={created}
          />
        )}
        onClickAnswer={onClickAnswerFun}
        {...othersProps}
      />
    </>
  );
};
