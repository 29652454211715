import React from 'react';

import { CommunityItem, message, Pagination } from 'base-components';
import cn from 'classnames';
import { useRouter } from 'next/router';

import {
  COMMUNITY_PATHS,
  COMMUNITY_STATUS,
  RELATION_TYPE
} from '@constants/index';
import { useFollowCommunity, withLogin } from '@hooks/index';
import { I18n } from '@utils/index';

import { CommunityListProps } from './type';

const CommunityList: React.FC<CommunityListProps> = (props) => {
  const {
    web3Auth,
    communityList,
    className,
    showPagination = true,
    ...otherProps
  } = props;
  const router = useRouter();
  const { onFollow } = useFollowCommunity(web3Auth);
  const handleCLickDetail = (status, id) => {
    if (status === COMMUNITY_STATUS.STOP) {
      message.warning(I18n.t('communityPaused'));
      return;
    }
    router.push({
      pathname: COMMUNITY_PATHS.COMMUNITY_DETAIL.replace('[id]', id)
    });
  };
  return (
    <div className={cn(className)}>
      {communityList?.map((item) => {
        const { id, title, desc, icon, relations, status } = item;
        console.log('line29item', relations?.includes(RELATION_TYPE.JOIN));
        return (
          <CommunityItem
            key={id}
            id={id}
            isJoin={relations?.includes(RELATION_TYPE.JOIN)}
            icon={icon}
            content={title}
            description={desc}
            status={status}
            onJoin={async () => {
              const res = await onFollow(false, id);
              return res;
            }}
            onJump={handleCLickDetail}
          />
        );
      })}
      {showPagination && <Pagination {...otherProps} />}
    </div>
  );
};

export default withLogin(CommunityList);
