import React, { useContext, useState } from 'react';

import { TabPane, Tabs } from 'base-components/index';
import cn from 'classnames';
import { useIsomorphicLayoutEffect } from 'hooks';
import { useRouter } from 'next/router';

import { HOME_PATHS } from '@constants/index';
import { IWeb3Auth, TRexContext, withLogin } from '@hooks/index';
import I18n from '@utils/i18n';

import { FollowFeed, RecommendFeed } from '../index';
import { homeTabsEum } from './const';

import styles from './feedTabs.module.less';

const FeedTabs: React.FC<{
  web3Auth: IWeb3Auth;
  scrollRef?: React.MutableRefObject<HTMLElement | null | undefined>;
}> = (props) => {
  const { className, web3Auth, scrollRef } = props;
  const {
    state: { isLogin }
  } = useContext(TRexContext);
  const { recommend, follow } = homeTabsEum;
  const [activeKey, setActiveKey] = useState(recommend);
  const router = useRouter();
  const onChange = async (activeKey) => {
    if (activeKey === follow && !isLogin) {
      await web3Auth?.current?.handleLogin?.();
      return;
    }
    setActiveKey(activeKey);
    const query =
      activeKey === follow
        ? {
            activeKey,
            followKey: 'community'
          }
        : { activeKey };
    router.replace({
      pathname: HOME_PATHS.INDEX,
      query: query
    });
  };
  useIsomorphicLayoutEffect(() => {
    getTabStatus();
  }, [router]);

  const getTabStatus = async () => {
    if (router.query.activeKey === follow && !isLogin) {
      router.replace({
        pathname: HOME_PATHS.INDEX,
        query: {
          activeKey: recommend
        }
      });
      web3Auth?.current?.handleLogin?.();
      return;
    }
    setActiveKey(router.query.activeKey as string);
  };
  return (
    <div className={cn(styles.container, className)}>
      <Tabs
        type="line"
        onChange={onChange}
        activeKey={activeKey}
        className={styles.tabWrapper}
      >
        <TabPane tab={<h2>{I18n.t('recommend')}</h2>} key={recommend}>
          <RecommendFeed activeKey={activeKey} scrollRef={scrollRef} />
        </TabPane>
        <TabPane tab={<h2>{I18n.t('following')}</h2>} key={follow}>
          <FollowFeed
            activeKey={activeKey}
            key="community"
            scrollRef={scrollRef}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};
export default withLogin(FeedTabs);
