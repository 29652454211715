import React, { useRef } from 'react';

import cn from 'classnames';
import dynamic from 'next/dynamic';

import styles from './scrollRegion.module.less';
const BackTop = dynamic(
  () => import('@components/BackTop').then((T) => T.BackTop),
  {
    ssr: false
  }
) as any;

export const ScrollRegion: React.FC<any> = (props) => {
  const { children, className } = props;
  const mainRef = useRef<any>(null);
  return (
    <div className={cn(styles.container, className)} ref={mainRef}>
      {children}
      <BackTop target={() => mainRef.current} />
    </div>
  );
};
