import { useRef } from 'react';

import { useIsomorphicLayoutEffect } from 'hooks'

export const useAsideHeight = () => {
  const ref = useRef<any>(null);
  useIsomorphicLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(([entry]) => {
      /** 208 = header高度72 + backTop距底部高度 80 + 右侧内容paddingTop24 + 32px */
      const stickyTop = (window.innerHeight - ref?.current?.clientHeight) < 228
        ? window.innerHeight - ref?.current?.clientHeight - 160 : 72;
      /** 160 = header高度72 + backTop距底部高度 80 + 8 */
      ref.current?.style.setProperty('--sticky-top', stickyTop + 'px');
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.unobserve(ref.current)
    }
  }, [])
  return { ref }
}
