import React, { ReactNode, useRef, useState } from 'react';

import cn from 'classnames';
import { useIsomorphicLayoutEffect } from 'hooks';
import { useRouter } from 'next/router';

import { NavBar } from '@components/index';

import styles from './navbarScroll.module.less';
interface navbarScrollProps {
  /** 滚动区域 节点 */
  scrollRef: string;
  /** 滚动距离 */
  scrollDistance: number;
  /** 初始导航 */
  normalNavBar?: ReactNode;
  /** 替换导航 */
  pageNav?: ReactNode;
  /** 页面key值 */
  menuKey?: string;
  /** 是否有返回按钮 */
  isBack?: boolean;
}
export const NavBarScroll: React.FC<navbarScrollProps> = (props) => {
  const {
    className,
    scrollRef,
    menuKey,
    isBack,
    pageNav,
    normalNavBar,
    scrollDistance = 0
  } = props;
  const router = useRouter();
  const [isShow, setIsShow] = useState(false);
  /** 记录上一次的滚动距离 用于判断滚动方向 */
  const scrollHeight = useRef<number>(0);
  useIsomorphicLayoutEffect(() => {
    const el = document.querySelector(scrollRef);
    el?.addEventListener('scroll', getScrollState);
    return () => {
      el?.removeEventListener('scroll', getScrollState);
    }
    // scrollHeight.current = scrollRef?.current?.scrollTop;
    // scrollRef?.current && router.isReady &&
    //   scrollRef?.current?.addEventListener('scroll', getScrollState);
    // return () => {
    //   console.log('line21remove', scrollRef?.current, router.asPath);
    //   scrollRef?.current?.removeEventListener('scroll', getScrollState)
    // };
  }, [router]);
  const getScrollState = (e) => {
    /** 滚动距离大于设置的距离，则导航替换 */
    if (
      e.target.scrollTop > scrollDistance
      /** 方向为向上滚动 */
      // && e.target.scrollTop > scrollHeight.current
    ) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
    scrollHeight.current = e.target.scrollTop;
  };
  return (
    <div className={cn(styles.container, className)}>
      {normalNavBar ?? (
        <NavBar
          isBack={isBack}
          tipContent={menuKey}
          className={cn(styles.normalNavbar, {
            [styles.isShow]: isShow
          })}
        />
      )}
      <div
        className={cn(styles.pageNavBar, {
          [styles.isHidden]: isShow
        })}
      >
        {pageNav}
      </div>
    </div>
  );
};
