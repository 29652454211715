import React, { useState } from 'react';

import { TabPane, Tabs } from 'base-components/index';
import cn from 'classnames';
import { useIsomorphicLayoutEffect } from 'hooks';
import { useRouter } from 'next/router';

import { PROFILE_PATHS } from '@constants/index';
import I18n from '@utils/i18n';
import { ActivityFeed, AnswerFeed, QuestionsFeed } from '@widget/index';

import { profileTabsEum } from './const';

import styles from './index.module.less';
type ProfileFeedTabsProps = {
  scrollEl?: HTMLElement | null | undefined;
}
const ProfileFeedTabs: React.FC<ProfileFeedTabsProps> = (props) => {
  const { className, scrollEl } = props;
  const { activity, questions, answer } = profileTabsEum;
  const [activeKey, setActiveKey] = useState(activity);
  const router = useRouter();
  const onChange = (activeKey) => {
    setActiveKey(activeKey);
    const query = router.query;
    router.replace({
      pathname: PROFILE_PATHS.INDEX,
      query: {
        ...query,
        activeKey
      }
    })
  }
  useIsomorphicLayoutEffect(() => {
    setActiveKey(router.query.activeKey as string);
  }, [router])
  return (
    <div className={cn(styles.container, className)}>
      <Tabs
        type="line"
        onChange={onChange}
        activeKey={activeKey}
        className={styles.tabWrapper}
      >
        <TabPane tab={<h2>{I18n.t('Activity')}</h2>} key={activity}>
          <ActivityFeed scrollEl={scrollEl} />
        </TabPane>
        <TabPane tab={<h2>{I18n.t('Questions')}</h2>} key={questions}>
          <QuestionsFeed />
        </TabPane>
        <TabPane tab={<h2>{I18n.t('Answer')}</h2>} key={answer}>
          <AnswerFeed scrollEl={scrollEl} />
        </TabPane>
      </Tabs>
    </div>
  );
};
export default ProfileFeedTabs;
