import { useContext, useEffect, useRef, useState } from 'react'

import { message, SPIN_STATUS } from 'base-components'
import { pubsub } from 'utils';

import { getMemberPointLedgers, PointLedgersItem } from '@api/index'
import { ACHIEVE_REFRESH } from '@constants/index';
import { TRexContext } from '@hooks/index';
import { removeBrackets } from '@utils/index';

const limit = 20

export const useScroll = () => {
  const rowsRef = useRef<PointLedgersItem[]>([])
  const [dataSource, setDataSource] = useState<PointLedgersItem[]>([])
  const offsetRef = useRef<number>(0)
  const [status, setStatus] = useState<SPIN_STATUS>(SPIN_STATUS.SUCCESS)
  const hasMore = useRef<boolean>(true)
  const { state: { isLogin } } = useContext(TRexContext);

  useEffect(() => {
    pubsub.subscribe(ACHIEVE_REFRESH, refresh)
    return () => pubsub.unSubscribe(ACHIEVE_REFRESH, refresh)
  }, [])

  useEffect(() => {
    if (!isLogin) {
      rest()
      return
    };
    hasMore.current = true
    fetch()
  }, [isLogin])

  const rest = () => {
    rowsRef.current = []
    offsetRef.current = 0
    hasMore.current = false
    setDataSource([])
  }

  const refresh = () => {
    rest()
    hasMore.current = true
    fetch()
  }

  const fetch = async () => {
    if (!hasMore.current) return
    setStatus(SPIN_STATUS.LOADING)
    try {
      const { success, obj } = await getMemberPointLedgers({
        type: 'transfer_in',
        offset: offsetRef.current,
        limit
      })
      if (success) {
        const newDataSource = [...rowsRef.current, ...obj.pointLedgers].map(i => ({
          ...i,
          descI18n: removeBrackets(i.descI18n)
        }))
        rowsRef.current = newDataSource
        setDataSource(newDataSource)
        offsetRef.current += limit
        setStatus(SPIN_STATUS.SUCCESS)
        hasMore.current = obj.pointLedgers.length !== 0
      }
    } catch (error) {
      message.error('system.error')
    }
  }

  const onScroll = () => fetch()
  return {
    dataSource,
    status,
    onScroll,
    hasMore: hasMore.current
  }
}
