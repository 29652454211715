import React, { useRef } from 'react';

import { AvatarText } from 'base-components';
import cn from 'classnames';
import { ArrowRight } from 'icons';
import Link from 'next/link';
import { customizeRelativeTime } from 'utils';

import { Operate } from '@components/Operate';
import { PROFILE } from '@constants/index';

import { CommentItemProps } from './type';

import styles from './index.module.less';

const CommentItemComponent: React.FC<CommentItemProps> = (props) => {
  const {
    id,
    text,
    userAvatar,
    username,
    replayUsername,
    replyId,
    avatarSize = 40,
    likeCount = 0,
    created,
    isLike,
    borderLeftLine,
    className,
    contentClassName,
    onClickReplay,
    status,
    linkTo = '',
    customerId = ''
  } = props;
  const replayRef = useRef<any>();

  const handleClickReplay = () => {
    if (typeof onClickReplay === 'function') {
      onClickReplay(replayRef, id);
    }
  }

  return (
    <div className={cn(styles.container, 'openSansSemiBold', {
      [styles.borderLine]: !!borderLeftLine
    }, className)}>
      <div className={styles.avatarContainer}>
        <AvatarText
          avatarSize={avatarSize}
          avatar={userAvatar}
          linkTo={linkTo}
          customerId={customerId}
          userName={<span className={styles.username}>{username}</span>}
        />
        {
          !!replayUsername &&
          <div className={styles.right}>
            <ArrowRight className={styles.arrowRight} />
            <Link href={`${PROFILE}?id=${replyId}`}>
              <span className={styles.username}>{replayUsername}</span>
            </Link>
          </div>
        }

      </div>
      <div className={cn(styles.content, contentClassName)}>
        <div className={styles.title}>
          {text}
        </div>
        <div className={styles.timeRow}>
          {customizeRelativeTime(created)}
          <Operate
            targetId={id}
            status={status}
            className={styles.operate}
            isLike={isLike}
            likeCount={likeCount}
            comment={handleClickReplay}
          />
        </div>
        <div className={styles.commentContainer} ref={replayRef}></div>
      </div>

    </div>
  )
}

export const CommentItem: React.FC<CommentItemProps> = React.memo(CommentItemComponent);
