import React from 'react';

import cn from 'classnames';
import { customizeRelativeTime } from 'utils';

import { Avatar, StickyItem } from '@components/index';
import I18n from '@utils/i18n';

import { RecommendItemProps } from './type';

import styles from './index.module.less';

export const RecommendItem: React.FC<RecommendItemProps> = (props) => {
  const {
    title, answerTime, nickName,
    userName, avatar, className, userLinkTo, onLinkClick = () => {}
  } = props;
  const renderHeader = () => {
    return (
      <>
        <div className={cn(styles.question, 'openSansSemiBold')}>{title}</div>
        <div className={styles.answer}>
          <div className={styles.userInfoWrapper}>
            <div className={styles.desc}>{I18n.t('answerDesc')}</div>
            <Avatar
              nickName={
                <div className={styles.userInfo}>
                  <span className={cn(styles.nickName, 'openSansSemiBold')}>{nickName}</span>
                  <span className={cn('openSansSemiBold', styles.userName)}>{userName}</span>
                </div>
              }
              linkTo={userLinkTo}
              onLinkClick={onLinkClick}
              avatarSize={20}
              avatar={avatar}
            />
          </div>
          {!!answerTime && <span className={styles.time}>{customizeRelativeTime(answerTime)}</span>}
        </div>
      </>
    )
  }

  return (
    <StickyItem
      {...props}
      header={renderHeader}
      className={cn(styles.recommendItem, className)}
    />
  );
};
