import React, { useCallback, useMemo, useRef } from 'react';

import { ScrollLoading, SPIN_STATUS } from 'base-components';
import cn from 'classnames';
import { useIsomorphicLayoutEffect } from 'hooks';
import { pubsub } from 'utils'

import { Empty } from '@components/index';
import { HOME_PATHS, QUESTION_SLUG, REFRESH } from '@constants/index'
import { useAnswerListPage, useQuestionDetail } from '@hooks/index';
import { RecommendItem } from '@widget/index';

import { homeTabsEum } from '../feedTabs/const';

import styles from './socialFeed.module.less';

export const RecommendFeed: React.FC<{ activeKey: string, scrollRef?: any }> = (props) => {
  const { className, scrollRef } = props;
  const { rows, status, nextToken, fetch } = useAnswerListPage({
    slug: QUESTION_SLUG.RECOMMEND,
    reverse: true,
    limit: 20,
    with_activity: true
  });
  const recommendRef = useRef(null);
  const { jumpDetail } = useQuestionDetail();
  useIsomorphicLayoutEffect(() => {
    fetch({ init: true });
  }, []);
  const onScroll = useCallback(() => {
    if (!nextToken.current) {
      return;
    }
    fetch({});
  }, []);
  const data = useMemo(() => {
    const list = rows?.filter((item) => {
      return item.activity;
    });
    if (list.length < 10 && nextToken.current) {
      onScroll();
    }
    return list;
  }, [rows]);
  const refresh = ({ pathname, query }) => {
    if (pathname === HOME_PATHS.INDEX && query.activeKey !== homeTabsEum.follow) return fetch({ init: true });
  }
  useIsomorphicLayoutEffect(() => {
    pubsub.subscribe(REFRESH, refresh);
    return () => pubsub.unSubscribe(REFRESH, refresh)
  }, [])
  return (
    <div className={cn(styles.container, className)} ref={recommendRef}>
      {status === SPIN_STATUS.SUCCESS && data.length === 0 && <Empty />}
      <ScrollLoading
        invisibleHeight={100}
        status={status}
        fetchData={onScroll}
        noMoreShow={!nextToken.current && rows.length !== 0}
      >
        {data.map((item) => {
          const {
            view_id: id = '',
            title,
            user_id: userId,
            activity: {
              comment_count: commentCount = 0,
              like_count: likeCount = 0,
              text: richContent = '',
              view_id: answerId = '',
              user: {
                nick_name: nickName = '',
                unique_name: userName = '',
                avatar = '',
                id: answerUserId = ''
              } = {},
              owns: { LIKE: isLike = false } = {}
            } = {}
          } = item;

          return (
            <RecommendItem
              title={title}
              scrollEl={scrollRef?.current}
              richContent={richContent}
              nickName={nickName}
              userName={userName}
              answerId={answerId}
              answerUserId={answerUserId}
              likeCount={likeCount}
              avatar={avatar}
              commentCount={commentCount}
              questionId={id}
              isLike={isLike}
              key={id}
              handleItemClick={(params) => jumpDetail({ ...params, questionUserId: userId, questionId: id })}
            />
          );
        })}
      </ScrollLoading>
    </div>
  );
};
