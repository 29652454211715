import { useState } from 'react';

import { pubsub } from 'utils';

import { getTaskReceieve } from '@api/achieve';
import { ACHIEVE_RECIVE } from '@constants/index';

export const useReceieve = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const recive = async (taskId: string) => {
    setLoading(true);
    const { success } = await getTaskReceieve(taskId);
    if (success) {
      setLoading(false);
      // callback()
      pubsub.publish(ACHIEVE_RECIVE, taskId)
    }
  };

  return {
    loading,
    recive
  }
}
