import React, { memo, useMemo } from 'react'

import { Button, Progress, TextMore } from 'base-components'
import cn from 'classnames'
import { useClient } from 'hooks';
import Image from 'next/image'
import { customizeRelativeTime2 } from 'utils';

import { TaskResItem, TaskStatus } from '@api/index'
import { Empty } from '@components/index'
import { I18n } from '@utils/index';

import { useReceieve } from './useReceieve';
import { useTask } from './useTask';

import styles from './AchieveTask.module.less'

interface AchieveItemProps {
    data: TaskResItem,
    // fetchList: any
}

const AchieveItem:React.FC<AchieveItemProps> = ({ data }) => {
  const isPc = useClient(576);
  const { loading, recive } = useReceieve()

  const {
    buttonText,
    disabled,
    haveButton,
    isDone,
    hasProgress,
    progress
  } = useMemo(() => {
    const buttonText = data.status === TaskStatus.RECEIVE ? I18n.t('recive') : I18n.t('Processing');
    const disabled = data.status !== TaskStatus.RECEIVE;
    const haveButton = data.status === TaskStatus.RECEIVE || data.status === TaskStatus.APPENDING;
    const isDone = data.status === TaskStatus.DONE;
    const hasProgress = (data.status === TaskStatus.APPENDING || data.status === TaskStatus.DONE) && data.completeTimes > 1;
    const progress = data.process / data.completeTimes * 100;
    return {
      buttonText,
      disabled,
      haveButton,
      isDone,
      hasProgress,
      progress
    }
  }, [data])

  return (
        <div className={styles.itemContain}>
            <div className={styles.top}>
            <div className={cn(styles.left)}>
                <div className={cn(styles.reward, {
                  [styles.mask]: isDone
                })}>
                    <Image
                        alt='trex'
                        src={data.icon}
                        width={32}
                        height={32}
                        className={styles.img}
                    />
                    <span className='openSansSemiBold'>{I18n.t('Q&ANum', { points: data.amount * data.completeTimes })}</span>
                </div>
                <div className={cn(styles.title, 'openSansSemiBold', {
                  [styles.mask]: isDone
                })}>
                   {I18n.t(`${data.nameI18n}`)}
                   {
                    isDone && (
                        <Image
                            alt='trex'
                            src={require('./media/success.webp')}
                            width={16}
                            height={16}
                            className={styles.successImg}
                        />
                    )
                   }
                </div>
                <TextMore className={styles.des}>
                    {I18n.t(`${data.remarkI18n}`)}
                </TextMore>
            </div>
            {
                isPc && haveButton && (
                    <Button
                        type='primary'
                        className='button'
                        loading={loading}
                        onClick={() => recive(data.id)}
                        disabled={disabled}
                        size='small'
                    >
                        {buttonText}
                    </Button>
                )
            }
            </div>
            <div className={cn(styles.bottom, {
              [styles.mask]: isDone
            })}>
                    <div className={styles.time}>{`${customizeRelativeTime2(data.startTime)} - 
                    ${customizeRelativeTime2(data.expiredTime)}`}</div>
                    {
                        hasProgress && (
                            <Progress
                                percent={progress}
                                showInfo={false}
                                size={isPc ? 'default' : [91, 4] }
                            />
                        )
                    }
                    {
                        hasProgress && (
                            <div className={styles.finished}>{
                                isPc ? I18n.t('finished', {
                                  process: data.process,
                                  completeTimes: data.completeTimes
                                }) : `(${data.process}/${data.completeTimes})`
                            }</div>
                        )
                    }
            </div>
            {
                !isPc && haveButton && (
                    <Button
                        type='primary'
                        className='button'
                        loading={loading}
                        onClick={() => recive(data.id)}
                        disabled={disabled}
                        size='small'
                    >
                        {buttonText}
                    </Button>
                )
            }
        </div>
  )
}

const T:React.FC = () => {
  const { taskList } = useTask()
  return (
    <div className={styles.task}>
            <h2 className={cn(styles.taskTitle, 'openSansSemiBold')}>{I18n.t('taskTitle')}</h2>
            {
               taskList.length > 0 ? taskList.map((i, index) => (
                    <AchieveItem key={`${index}${i.id}`} data={i}/>
               )) : <Empty
                emptyTitle={I18n.t('emptyWater')}
                emptyDesc={I18n.t('emptyWaterDes')}
              />
            }
    </div>
  )
}

export const AchieveTask = memo(T)
