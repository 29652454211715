import React from 'react';

import cn from 'classnames';

import { I18n } from '@utils/index';

import { CommunityList } from '../list';
import { RelationCommunityProps } from './type'

import styles from './index.module.less';

export const RelationCommunity: React.FC<RelationCommunityProps> = (props) => {
  const { dataList = [], className } = props;

  if (!dataList?.length) {
    return null;
  }

  return (
    <div className={cn(styles.container, className)}>
      <div className={cn(styles.title, 'openSansSemiBold')}>{I18n.t('relativeCommunity')}</div>
      <CommunityList
        communityList={dataList}
        showPagination={false}
      />
    </div>
  )
};
