import React, { useMemo } from 'react';

import cn from 'classnames';

import { useMyJoinCommunity } from '@hooks/index';
import { I18n } from '@utils/index';

import { CommunityList } from '../list';

import styles from './index.module.less';

export const MyJoinCommunity: React.FC<{ userId?: string }> = (props) => {
  const { userId } = props;
  const { dataList, preDisabled, nextDisabled, loadNext, loadPre } =
    useMyJoinCommunity({ userId: userId });
  const followedList = useMemo(() => {
    const list = dataList?.map((item: any) => {
      return {
        ...item?.target,
        relations: item?.relations
      };
    });
    return list;
  }, [dataList]);
  return (
    <>
      {followedList && followedList?.length && (
        <div className={styles.container}>
          <div className={cn(styles.title, 'openSansSemiBold')}>{I18n.t('myCommunity')}</div>
          <CommunityList
            communityList={followedList}
            preDisable={preDisabled}
            nextDisable={nextDisabled}
            clickPre={loadPre}
            clickNext={loadNext}
          />
        </div>
      )}
    </>
  );
};
