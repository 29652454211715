import React, { useContext } from 'react';

import { Layout } from 'antd';
import cn from 'classnames';
import { useIsomorphicLayoutEffect } from 'hooks';
import dynamic from 'next/dynamic';
import { openConfirmUserNameModal } from 'widgets';

import { TRexContext, useAddQuestion } from '@hooks/index';

import TRexMenu from './components/menu';
import { useOnVerify } from './onVerify';
import { LayoutProps } from './type';

import styles from './index.module.less';

const { Sider } = Layout;
const BackTop = dynamic(
  () => import('@components/BackTop').then((T) => T.BackTop),
  {
    ssr: false
  }
) as any;
export const TLayout: React.FC<LayoutProps> = (props) => {
  const {
    children,
    isShow = true,
    className,
    defaultKey = '',
    scrollRef,
    scrollEl,
    onLogoClick
  } = props;
  const { onClickPostFun } = useAddQuestion();
  const { state } = useContext(TRexContext);
  const { isLogin, userInfo } = state;
  const onCreate = () => {
    onClickPostFun?.();
  };
  const { onVerify } = useOnVerify();
  useIsomorphicLayoutEffect(() => {
    if (!isLogin) return;
    updateUserName();
  }, [isLogin]);
  const updateUserName = async () => {
    if (userInfo?.uniqueName) return;
    const res = await openConfirmUserNameModal(onVerify);
    console.log('res', res);
  };
  console.log('line27', scrollRef, scrollRef?.current);
  return (
    <Layout className={cn(styles.layout, className)} hasSider>
      {/* 通过breakpoint 控制在移动端的sider尺寸 lg:992 */}
      <Sider
        breakpoint="lg"
        width="250"
        collapsedWidth="108"
        className={cn(styles.sider, {
          [styles.isShowSider]: !isShow
        })}
      >
        <TRexMenu
          mode="inline"
          selectedKeys={[defaultKey]}
          style={{ height: '100%', borderRight: 0 }}
          onCreate={onCreate}
          onLogoClick={onLogoClick}
        />
      </Sider>
      <Layout className={cn(scrollEl, styles.Tcontent)} ref={scrollRef}>
        {children}
      </Layout>
      <BackTop className={styles.backTop} target={() => scrollRef?.current} />
    </Layout>
  );
};
