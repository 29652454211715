import React from 'react';

import { SPIN_STATUS } from 'base-components';

import { QuestionItem } from '@api/index';
import { IWeb3Auth } from '@hooks/index';
export interface followFeedProps {
  data: Array<QuestionItem>;
  status: SPIN_STATUS;
  onScroll: () => void;
  activeKey: string;
  web3Auth: IWeb3Auth;
  scrollRef: React.MutableRefObject<HTMLElement | null | undefined>;
  nextToken?: React.MutableRefObject<string | undefined>;
}
export interface followItemProps {
  item: QuestionItem;
  linkTo: string;
  activeKey: string;
  scrollRef: React.MutableRefObject<HTMLElement | null | undefined> | undefined;
}
