import React, { useContext } from 'react';

import { getImagesByRichText } from 'base-components';
import { AnswerItem } from 'widgets';

import { dynamicTypeEum } from '@api/index';
import { PROFILE } from '@constants/index';
import { TRexContext, useAddAnswer, withLogin } from '@hooks/index';
import { CommunityItem, RecommendItem, ReplyItem } from '@widget/index';

import { ActivityItemProps } from './type';

import styles from './index.module.less';

export const DistributeItemWithLogin: React.FC<ActivityItemProps> = (props) => {
  const { scrollEl, handleItemClick, web3Auth, ...data } = props;
  const { onClickAnswerFun } = useAddAnswer({ web3Auth });
  const { state } = useContext(TRexContext);
  const { userInfo } = state;
  const {
    verb,
    collection: {
      title = '',
      child_activity_count: activityCount = 0,
      view_id: questionId = '',
      status = '',
      user_id: questionUserId = ''
    } = {},
    activity: {
      comment_count: commentCount = 0,
      like_count: likeCount = 0,
      text: richContent = '',
      view_id: answerId = '',
      user_id: answerUserId,
      user: {
        nick_name: nickName = '',
        unique_name: uniqueName = '',
        avatar = ''
      } = {},
      owns: { LIKE: isLike = false } = {}
    } = {},
    reaction: {
      text: replyText = '',
      user: {
        id: replyUserId = '',
        nick_name: replyNickName = '',
        unique_name: replyUniqueName = '',
        avatar: replyAvatar = ''
      } = {}
    } = {},
    community: {
      id: communityId = '',
      title: communityTitle = '',
      desc: communityDesc = '',
      icon = '',
      status: communityStatus = ''
    } = {}
  } = data;
  const commonParams = {
    questionId,
    questionUserId,
    answerId,
    answerUserId,
    status
  }
  const answerImages = getImagesByRichText(richContent);
  const handleAnswerClick = () => {
    onClickAnswerFun?.({
      view_id: questionId,
      title,
      status,
      user: {
        nick_name: userInfo?.nickName || '',
        avatar: userInfo?.avatar || ''
      }
    });
  }
  const handleLinkClick = () => {
    scrollEl?.scrollTo(0, 0);
  }
  switch (verb) {
    case dynamicTypeEum.ANSWER:
    case dynamicTypeEum.LIKE_ANSWER:
      return (
        <RecommendItem
          title={title}
          userLinkTo={`${PROFILE}?id=${answerUserId}`}
          onLinkClick={handleLinkClick}
          className={styles.questionItem}
          scrollEl={scrollEl}
          richContent={richContent}
          nickName={nickName}
          userName={uniqueName}
          likeCount={likeCount}
          avatar={avatar}
          commentCount={commentCount}
          isLike={isLike}
          handleItemClick={handleItemClick}
          {...commonParams}
        />
      )
    case dynamicTypeEum.COMMENT_ANSWER:
      return (
        <ReplyItem
          title={title}
          imgUrl={answerImages[0] || ''}
          desc={richContent}
          onLinkClick={handleLinkClick}
          richContent={replyText}
          nickName={replyNickName}
          userName={replyUniqueName}
          avatar={replyAvatar}
          replyUserId={replyUserId}
          handleItemClick={handleItemClick}
          {...commonParams}
        />
      )
    case dynamicTypeEum.JOIN_COMMUNITY:
      return (
        <CommunityItem
          communityId={communityId}
          status={communityStatus}
          verb={verb}
          title={communityTitle}
          imgWidth={64}
          imgHeight={64}
          desc={communityDesc}
          imgUrl={icon}
          className={styles.communityItem}
          imgClassName={styles.communityImg}
          handleItemClick={handleItemClick}
        />
      )
    case dynamicTypeEum.PUBLISH_COLLECTION:
      return (
        <AnswerItem
          customUserInfo={<></>}
          className={styles.questionItem}
          title={title}
          handleItemClick={handleItemClick}
          activityCount={activityCount}
          onClickAnswer={handleAnswerClick}
          {...commonParams}
        />
      )
    default: return null;
  }
};

export const DistributeItem = withLogin(DistributeItemWithLogin);
