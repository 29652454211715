import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';

import { Button } from 'base-components';
import { useClient } from 'hooks';
import { Comment } from 'widgets';

import { useCommentData } from '@hooks/index';
import { getUserInfo, I18n } from '@utils/index';

import { Item } from '../item';
import { CommentListProps, CommentListRefType } from './type';
import { useSticky } from './useSticky';

import styles from './index.module.less';

const LIMIT = 5;
const DEFAULT_LIMIT = 3;

const CommentListComponent: React.ForwardRefRenderFunction<
  CommentListRefType,
  CommentListProps
> = (props, ref) => {
  const {
    web3Auth,
    scrollEl,
    itemRef,
    inModal = false,
    limit = LIMIT,
    defaultLimit = DEFAULT_LIMIT,
    answerId,
    onCommentSuccess,
    handleHideComment
  } = props;
  const isPc = useClient(576);
  const userInfo = getUserInfo()

  const { containerRef, inputRef } = useSticky({ scrollEl, itemRef, isPc });
  const [defaultValue, setDefaultValue] = useState('');
  const { commentData, nextToken, fetchMoreData, saveComment } = useCommentData(
    {
      web3Auth,
      answerId,
      limit,
      defaultLimit,
      defaultCommentList: []
    }
  );
  useImperativeHandle(ref, () => ({
    fetchData: fetchMoreData,
    handleSubmit
  }));
  useEffect(() => {
    getInModal();
  }, [inModal]);
  const getInModal = async () => {
    const { queryLastBulletin } = await import('./utils/commentDb');
    if (!inModal) {
      const dbData = await queryLastBulletin();
      if (dbData?.id === answerId) {
        setDefaultValue(dbData?.value);
      }
    }
  };
  const onBlur = async (value) => {
    const { addData, updateBulletin, queryLastBulletin } = await import(
      './utils/commentDb'
    );
    const dbData = await queryLastBulletin();
    console.log('line101', dbData);
    const newData = {
      id: answerId,
      value: value
    };
    if (dbData?._rev) {
      updateBulletin({
        ...dbData,
        ...newData
      });
      return;
    }
    addData(newData);
  };
  const handleSubmit = async ({
    id,
    value,
    kind,
    removePreview,
    resolve
  }: {
    id: string;
    value: string;
    kind?: string;
    resolve?: <P = unknown>(value?: P) => void;
    removePreview?: () => void;
  }) => {
    try {
      const res = await saveComment(id, value, kind);
      if (res?.success) {
        onBlur('');
        removePreview && removePreview();
        resolve && resolve();
        if (id === answerId && typeof onCommentSuccess === 'function') {
          onCommentSuccess(answerId);
        }
        return Promise.resolve(true);
      }
      return Promise.resolve(false);
    } catch (error) {
      return Promise.resolve(false);
    }
  };

  return (
    <div className={styles.container} ref={containerRef}>
      {!inModal && (
        <div className={styles.comment} ref={inputRef}>
          <div className={styles.hideComment}>
            <div className={styles.hideCommentBtn} onClick={handleHideComment}>
              {I18n.t('hideComment')}
            </div>
          </div>
          <Comment
            className={styles.commentInput}
            avatar={userInfo?.avatar}
            defaultValue={defaultValue}
            onBlur={onBlur}
            getUserInfo={getUserInfo}
            onSubmit={(value) => {
              const res = handleSubmit({ id: answerId, value })
              return res
            }}
          />
        </div>
      )}
      <div>
        {commentData?.map((item) => (
          <Item
            key={item.view_id}
            commentItem={item}
            web3Auth={web3Auth}
          />)
        )}
        {
          !inModal && !!nextToken &&
          <Button
            className={styles.moreCommentButton}
            type="primary"
            ghost
            onClick={fetchMoreData}
          >
            See the full review
          </Button>
        }
      </div>
    </div>
  );
};

export const CommentList = forwardRef<CommentListRefType, CommentListProps>(
  CommentListComponent
);
