import React from 'react';

import { GoBack } from 'base-components/index';
import cn from 'classnames';

import LoginInfo from './LoginInfo';
import type { NavBarProps } from './type';

import styles from './navBar.module.less';

const NavBar: React.FC<NavBarProps> = (props) => {
  const { tipContent, className, isBack } = props;
  return (
    <div className={cn(styles.wrapper, className)}>
      <div className={cn(styles.content, 'openSansSemiBold')}>{!isBack ? <>{tipContent}</> : (
        <GoBack text={'Back'} />
      )}</div>
      <LoginInfo/>
    </div>
  );
};

export default NavBar;
