import React from 'react';

import cn from 'classnames';

import { useRecommendCommunity } from '@hooks/index';
import { I18n } from '@utils/index';

import { CommunityList } from '../list';

import styles from './index.module.less';

export const RecommendCommunity: React.FC<{ excludeId?: string }> = (props) => {
  const { excludeId } = props;
  console.log('lineExcludeId', excludeId);
  const { cacheList, dataList, preDisabled, nextDisabled, loadNext, loadPre } =
    useRecommendCommunity({ id: excludeId });
  console.log('line17', cacheList, preDisabled);
  return (
    <>
      {cacheList && cacheList?.length && (
        <div className={styles.container}>
          <div className={cn(styles.title, 'openSansSemiBold')}>
            {I18n.t('niceCommunity')}
          </div>
          <CommunityList
            communityList={dataList}
            preDisable={preDisabled}
            nextDisable={nextDisabled}
            clickPre={loadPre}
            clickNext={loadNext}
          />
        </div>
      )}
    </>
  );
};
