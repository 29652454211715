import React from 'react';

import { openModal } from 'base-components';

import PopContent from './PopoverContent';

import styles from './navBar.module.less';

const openUserInfoH5Modal = async (props) => {
  return openModal({
    mask: true,
    closable: true,
    direction: 'right',
    closePosition: 'right',
    cardClass: styles.H5Drawer,
    children: (params) => <PopContent {...params} {...props} isDrawer={true}/>
  });
};

export { openUserInfoH5Modal };
