import React from 'react';

import { AvatarText } from 'base-components/index';
import type { ImageProps } from 'next/image'

import { TRexAvatarProps } from './type'
import { identificationSwitch } from './utils';

const defaultAvatar = require('./media/defaultAvatar.webp') as ImageProps['src'];

const TRexAvatar: React.FC<TRexAvatarProps> = (props) => {
  const {
    identification,
    ...rest

  } = props

  const identificationSource = identificationSwitch(identification)

  return (
    <AvatarText
    identificationSource={identificationSource}
    {...rest}
    defaultAvatar={rest.defaultAvatar || defaultAvatar}
    />
  )
}

export default TRexAvatar
