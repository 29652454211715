import { useContext, useRef, useState } from 'react'

import { useIsomorphicLayoutEffect } from 'hooks';
import { pubsub } from 'utils';

import { getTask, TaskResItem, TaskStatus } from '@api/index'
import { ACHIEVE_RECIVE } from '@constants/index';
import { TRexContext } from '@hooks/index';
import { removeBrackets } from '@utils/index';

export const useTask = () => {
  const [taskList, setTaskList] = useState<TaskResItem[]>([])
  const { state: { isLogin } } = useContext(TRexContext);
  const taskIDRef = useRef<string[]>([])

  useIsomorphicLayoutEffect(() => {
    // pubsub.subscribe(ACHIEVE_REFRESH, fetchList)
    pubsub.subscribe(ACHIEVE_RECIVE, updateList)
    return () => {
      // pubsub.unSubscribe(ACHIEVE_REFRESH, updateList)
      pubsub.unSubscribe(ACHIEVE_RECIVE, updateList)
    }
  }, [taskList])

  useIsomorphicLayoutEffect(() => {
    if (!isLogin) {
      setTaskList([])
      return
    };
    fetchList()
  }, [isLogin])

  const fetchList = async () => {
    const { obj, success } = await getTask({ type: 0 })
    if (success) {
      const formatObj = obj.map(item => {
        return {
          ...item,
          nameI18n: removeBrackets(item.nameI18n),
          remarkI18n: removeBrackets(item.remarkI18n)
        }
      })
      setTaskList(formatObj)
    }
  }

  const updateList = (taskId) => {
    taskIDRef.current.push(taskId)
    const taskIDList = taskIDRef.current
    const newTaskList = taskList.map(item => {
      if (taskIDList.some(id => id === item.id)) {
        return {
          ...item,
          status: item.event === 'SIGN_IN' ? TaskStatus.DONE : TaskStatus.APPENDING
        }
      }
      return item
    })
    setTaskList(newTaskList)
  }

  return {
    taskList
  }
}
