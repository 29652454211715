import React from 'react';

import { RichEditor } from 'base-components/rich-editor';
import cn from 'classnames';
import Image from 'next/image';

import I18n from '@utils/i18n';

import DeleteAnswerIcon from './media/deleteAnswer.svg';
import { CommunityItemProps } from './type';

import styles from './index.module.less';

export const CommunityItem: React.FC<CommunityItemProps> = (props) => {
  const {
    className, imgClassName, imgUrl,
    title, desc, imgWidth, imgHeight,
    isDelete = false, titleLineCount = 1,
    handleItemClick
  } = props;
  const isClick = typeof handleItemClick === 'function';

  const handleCommunityClick = () => {
    isClick && handleItemClick({ ...props })
  }
  return (
    <div className={styles.communityItemWrapper} onClick={handleCommunityClick}>
      {isDelete ? (
        <div className={styles.deleteContent}>
          <DeleteAnswerIcon />
          <div className={styles.deleteDesc}>{I18n.t('contentNoExists')}</div>
        </div>
      ) : (
        <div className={cn(className, styles.communityItem)}>
          {
            !!imgUrl && (
              <Image
                alt='trex'
                src={imgUrl}
                width={imgWidth}
                height={imgHeight}
                className={imgClassName}
              />
            )
          }
          <div className={cn(styles.content, {
            [styles.line1]: titleLineCount === 1,
            [styles.line2]: titleLineCount === 2
          })}>
            <div className={styles.title}>{title}</div>
            <div className={styles.desc}>
              <RichEditor
                summaryClass={styles.richEditorSummary}
                defaultValue={desc}
                readOnly
                moreClass={styles.more}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
