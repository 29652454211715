import React from 'react';

import cn from 'classnames';
import { customizeRelativeTime } from 'utils';

import { Avatar, StickyItem } from '@components/index';
import { PROFILE_PATHS } from '@constants/index';

import styles from './baseItem.module.less';
export const QuestionItem = (props) => {
  const {
    text,
    created,
    avatar,
    likeCount = 0,
    scrollEl,
    commentCount = 0,
    nickName = '',
    isLike,
    userName = '',
    answerId = '',
    answerUserId = '',
    className,
    index,
    replyUserId,
    curAnswerId
  } = props;
  const isFirst = index === 0;
  return (
    <StickyItem
      className={cn(styles.recommend, 'openSansSemiBold', className)}
      richContent={text}
      answerId={answerId}
      scrollEl={scrollEl}
      likeCount={likeCount}
      isLike={isLike}
      defaultIsExpend={isFirst}
      commentCount={commentCount}
      replyUserId={replyUserId}
      curAnswerId={curAnswerId}
      header={
        <div className={styles.answer}>
          <Avatar
            userInfoClassName={styles.userInfoWrapper}
            linkTo={PROFILE_PATHS.INDEX}
            customerId={`?id=${answerUserId}`}
            nickName={
              <div className={styles.userInfo}>
                <div className={cn(styles.nickName, 'openSansSemiBold')}>{nickName}</div>
                <div className={styles.userName}>
                  <span>{userName}</span>{' '}
                  <span>{customizeRelativeTime(created)}</span>
                </div>
              </div>
            }
            avatarSize={40}
            avatar={avatar}
          />
        </div>
      }
    />
  );
};
