import React from 'react';

import Link from 'next/link';

import { useQuestionDetail } from '@hooks/index';
import { I18n, linkToMap } from '@utils/index';
import { FollowedItem, followItemProps } from '@widget/index';

import styles from './socialFeed.module.less';

export const FollowedWrapper: React.FC<followItemProps> = (props) => {
  const { item, linkTo, activeKey, scrollRef } = props;
  const { jumpDetail } = useQuestionDetail();
  const {
    view_id: id = '',
    created,
    title,
    like_count: answerLikeCount = 0,
    comment_count: answerCommentCount = 0,
    owns: { LIKE: answerIsLike = false } = {},
    user_id: collectionUserId = '',
    text: answerRichContent = '',
    activity: {
      comment_count: commentCount = 0,
      like_count: likeCount = 0,
      text: richContent = '',
      view_id: answerId = '',
      user: { nick_name: nickName = '', id: answerUserId = '' } = {},
      owns: { LIKE: isLike = false } = {}
    } = {},
    collection: {
      view_id: questionId = '',
      title: questionTitle = '',
      user_id: questionUserId = ''
    } = {},
    community: {
      title: communityName = '',
      icon = ''
    } = {},
    user: {
      avatar = '',
      id: userId = '',
      nick_name: name = '',
      unique_name: uniqueName = ''
    } = {}
  } = item;
  const time = activeKey === 'community' ? 0 : created;
  return (
    <FollowedItem
      key={id}
      created={time}
      scrollEl={scrollRef?.current}
      answerUserId={answerUserId || collectionUserId}
      title={questionTitle || title}
      linkTo={linkTo}
      communityName={communityName}
      headerInfo={activeKey === 'community'
        ? <Link className={styles.postAnswer}
          href={linkToMap.user(answerUserId)}>{I18n.t('answerDesc')}<span>{nickName}</span></Link>
        : <div className={styles.postAnswer}>{I18n.t('postedAnswer')}</div>}
      nickName={name}
      userName={activeKey === 'user' ? uniqueName : ''}
      richContent={richContent || answerRichContent}
      answerId={answerId || id}
      avatar={icon || avatar}
      questionId={questionId || id}
      handleItemClick={(params) => jumpDetail({
        ...params,
        questionUserId: questionUserId || userId,
        questionId: questionId || id
      })}
      likeCount={likeCount || answerLikeCount}
      commentCount={commentCount || answerCommentCount}
      isLike={isLike || answerIsLike}
    />
  );
};
