import React, { useContext, useMemo, useState } from 'react';

import { Popover } from 'antd';
import { Button } from 'base-components/index';
import cn from 'classnames';
import { useClient } from 'hooks';
import { ArrowExpand } from 'icons';
import { openLogoutModal } from 'widgets';

import { Avatar } from '@components/index';
import { TRexContext, withLogin } from '@hooks/index';
import I18n from '@utils/i18n';

import PopContent from './PopoverContent';
import type { NavBarProps } from './type';
import { openUserInfoH5Modal } from './UserInfoModal';

import styles from './navBar.module.less';

const LoginInfo: React.FC<NavBarProps> = (props) => {
  const { web3Auth, className } = props;
  const { state } = useContext(TRexContext);
  const { isLogin, userInfo, walletAddress = '' } = state;
  const [isExpand, setIsExpand] = useState(false);
  const isPc = useClient(576);
  const onConnect = () => {
    console.log('登录');
    web3Auth?.current?.handleLogin?.();
  };
  const onOpenChange = (e) => {
    setIsExpand(e);
  };
  const onDisconnect = async () => {
    setIsExpand(false);
    const res = await openLogoutModal();
    console.log('res', res);
    if (res) {
      web3Auth?.current?.handleLogout?.();
    }
  };
  // H5 登录信息为抽屉展开形式
  const onModalOpen = async () => {
    const res = await openUserInfoH5Modal({
      nickName: walletAddress,
      address: name,
      avatar: userInfo?.avatar,
      userName: userInfo?.uniqueName ? userInfo?.uniqueName : '',
      onClick: onDisconnect
    });
    console.log('line90res', res);
  };
  const name = useMemo(() => {
    if (!walletAddress) {
      return '';
    }
    const str =
      walletAddress.length > 10
        ? `${walletAddress?.slice(0, 4)}...${walletAddress?.slice(
          walletAddress?.length - 6,
          walletAddress?.length
        )}`
        : walletAddress;
    return str.toUpperCase();
  }, [walletAddress]);
  return (
    <div className={cn(styles.userInfo, className)}>
      {!isLogin ? (
        <>
          {isPc ? (
            <Button
              type="primary"
              ghost
              onClick={onConnect}
              className={styles.connectBtn}
            >
              {I18n.t('connectWallet')}
            </Button>
          ) : (
            <span
              onClick={onConnect}
              className={cn(styles.connectBtnH5, 'openSansSemiBold')}
            >
              {I18n.t('connectWallet')}
            </span>
          )}
        </>
      ) : (
        <>
          {isPc ? (
            <Popover
              content={
                <PopContent
                  nickName={walletAddress}
                  avatar={userInfo?.avatar ? userInfo?.avatar : ''}
                  address={name}
                  userName={userInfo?.uniqueName ? userInfo?.uniqueName : ''}
                  onClick={onDisconnect}
                />
              }
              title=""
              trigger={'hover'}
              open={isExpand}
              arrow={false}
              overlayClassName={styles.loginWrapper}
              onOpenChange={onOpenChange}
            >
              <div className={styles.info}>
                <Avatar
                  avatar={userInfo?.avatar ? userInfo?.avatar : ''}
                  avatarSize={32}
                  nickName={
                    <div className={styles.iconWrapper}>
                      <span>{name}</span>
                      <ArrowExpand
                        className={cn(styles.icon, {
                          [styles.isExpand]: isExpand
                        })}
                      />
                    </div>
                  }
                />
              </div>
            </Popover>
          ) : (
            <div className={styles.info} onClick={onModalOpen}>
              <Avatar
                avatar={userInfo?.avatar ? userInfo?.avatar : ''}
                avatarSize={32}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default withLogin(LoginInfo);
