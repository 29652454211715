import React, { memo } from 'react'

import cn from 'classnames'
import Image from 'next/image'

import { ACHIEVE_LIST } from '@constants/index'
import { getAnchorPosition, I18n } from '@utils/index';

import Tip from './media/tip.svg'
import { useAchieve } from './useAchieve'

import styles from './title.module.less'

const T: React.FC = () => {
  const points = useAchieve()
  const pointClick = () => getAnchorPosition(ACHIEVE_LIST)
  return (
    <div className={styles.overview}>
      <Image
        alt='trex'
        src={require('../media/integral.webp')}
        width={100}
        height={84}
        className={styles.img}
      />
      <div className={styles.right}>
        <div className={cn(styles.title, 'openSansSemiBold')}>
          {I18n.t('pointsOverview')}
          <Tip className={styles.icon}/>
        </div>
          <div className={cn(styles.value, 'openSansBold')}
            onClick={pointClick}
          >
            {I18n.t('points', { point: points })}
          </div>
      </div>
    </div>
  )
}

export const AchieveTitle = memo(T)
