import React, { useCallback, useContext, useMemo } from 'react';

import { message, ScrollLoading, SPIN_STATUS } from 'base-components'
import { useIsomorphicLayoutEffect } from 'hooks';
import { useRouter } from 'next/router';
import { type AnswerItemProps, AnswerItem } from 'widgets';

import { Empty } from '@components/index';
import { QUESTION_PATHS, QUESTION_SLUG, QUESTION_STATUS, QUESTION_TYPE } from '@constants/index';
import { useAnswerListPage } from '@hooks/answer';
import { TRexContext, useAddAnswer, withLogin } from '@hooks/index';
import { getAnswerAndPlateMsg } from '@utils/answer/helper';
import I18n from '@utils/i18n';

import { IProfileRouterQuery, QuestionsFeedProps } from './type';

const QuestionsFeedWithLogin: React.FC<QuestionsFeedProps> = (props) => {
  const { className, web3Auth } = props;
  const { state: { isLogin, userInfo } } = useContext(TRexContext);
  const userId = userInfo?.id || '';
  const router = useRouter();
  const { id = '' } = router.query as IProfileRouterQuery;
  const isMySelf = !id || id === userId;
  const { rows, status, nextToken, fetch } = useAnswerListPage({
    slug: QUESTION_SLUG.USER,
    reverse: true,
    with_user: true,
    with_community: true,
    user_id: isMySelf ? userId : id,
    'params[a_user]': isMySelf ? userId : id,
    'params[a_status]': `${QUESTION_TYPE.NORMAL},${QUESTION_TYPE.STOP}`
  });
  const getData = useCallback(() => {
    fetch({ init: true });
  }, [fetch]);

  useIsomorphicLayoutEffect(() => {
    if (!isLogin && !id) return;
    getData();
  }, [id, isLogin, userInfo?.nickName, userInfo?.avatar]);

  const curStatus = useMemo(() => {
    return isLogin ? status : SPIN_STATUS.SUCCESS;
  }, [status, isLogin]);

  const onScroll = () => {
    if (!nextToken.current) {
      return;
    }
    fetch({});
  };

  const handleQuestionDetail = (item: AnswerItemProps) => {
    const { questionId = '', status, customerId } = item;
    if (status === QUESTION_STATUS.STOP) {
      message.info(I18n.t('collection.not.found'))
      return
    }
    router.push({
      pathname: QUESTION_PATHS.QUESTION_DETAIL.replace('[id]', questionId),
      query: { questionUserId: customerId }
    });
  };

  const { onClickAnswerFun } = useAddAnswer({ web3Auth });

  return (
    <div className={className}>
      <ScrollLoading
        invisibleHeight={100}
        status={curStatus}
        fetchData={onScroll}
        noMoreShow={!nextToken.current && rows?.length !== 0}
      >
        {curStatus === SPIN_STATUS.SUCCESS && rows.length === 0 && <Empty emptyTitle={I18n.t('emptyNormal')} emptyDesc=" " />}
        {rows.map((item) => {
          const {
            view_id: id = '',
            title,
            created,
            status,
            child_activity_count: activityCount = 0,
            user
          } = item;
          const res = getAnswerAndPlateMsg(user || {}, {});
          return (
            <AnswerItem
              handleItemClick={(params) => handleQuestionDetail({ ...params, customerId: item.user_id })}
              key={id}
              questionId={id}
              status={status}
              customUserInfo={<></>}
              title={title}
              created={created}
              activityCount={activityCount}
              onClickAnswer={() => {
                onClickAnswerFun?.(item);
              }}
              {...res}
              linkTo=''
            />
          );
        })}
      </ScrollLoading>
    </div>
  );
};

export const QuestionsFeed = withLogin(QuestionsFeedWithLogin);
